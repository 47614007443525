import React, { useState, useEffect } from 'react';
import { InputNumber, Space } from 'antd';

const RefundCheckbox = ({ transactionComponents, isShow, leftButtonAction, confirmRefund }) => {
  const [transactions, setTransactions] = useState([]);
  const [refundDescription, setRefundDescription] = useState('');
  const [refundError, setRefundError] = useState('');

  useEffect(() => {
    const newComponents = transactionComponents.map((comp) => {
      comp.refund_amount = 0;
      return comp;
    });
    setTransactions(newComponents);
  }, transactionComponents);

  const handleAmountChange = (refundAmount, transaction) => {
    const index = transactions.map((t) => t.label).indexOf(transaction.label);
    let newTransactions;

    if (index > -1) {
      newTransactions = transactions.map((t) => {
        if (t.label === transaction.label) {
          if (refundAmount > t.amount) {
            t.refund_amount = t.amount;
          } else {
            t.refund_amount = refundAmount;
          }
        }
        return t;
      });
    }
    setTransactions(newTransactions);
    setRefundError('');
  };

  const handleSendRequest = (transactions, refundDescription) => {
    let errorText;
    if (!transactions?.length) {
      errorText = 'Please, select at least one of boxes';
    }
    const isAmountNotValid = transactions?.find((t) => !(t.refund_amount || t.refund_amount === 0));
    if (isAmountNotValid) {
      errorText = 'Please, input valid amount';
    }
    if (errorText) {
      setRefundError(errorText);
      return;
    }
    confirmRefund(transactions, refundDescription);
  };

  if (!isShow) {
    return <div />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2 className="card-title">Please adjust each refund</h2>
      {transactionComponents.map((c) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: 10,
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: 10,
            }}
          >
            <label htmlFor={c.label} style={{ marginTop: 0, marginBottom: 0, marginLeft: 5 }}>
              {' '}
              {c.label} {`(Max:${c.amount.toFixed(2)})`}
            </label>
          </div>
          <InputNumber
            min={0}
            max={c.amount.toFixed(2)}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(e) => handleAmountChange(e, c)}
            defaultValue={0}
            size="large"
            style={{ width: '120px' }}
          />
        </div>
      ))}
      <input
        style={{
          width: '95%',
          marginTop: 10,
          marginLeft: 10,
        }}
        className="form-control"
        value={refundDescription}
        placeholder="Refund description"
        onChange={(event) => setRefundDescription(event.target.value)}
      />
      {refundError && <div style={{ color: 'red' }}>{refundError}</div>}
      <div style={{ display: 'flex', justifyContent: 'center', margin: '40px 0px 0px 0px' }}>
        <button
          className="btn btn-secondary"
          style={{ marginRight: '12px', width: 150 }}
          onClick={() => leftButtonAction()}
          type="button"
        >
          Back
        </button>
        <button
          className="btn btn-danger"
          style={{ marginLeft: '12px', width: 150 }}
          onClick={() => handleSendRequest(transactions, refundDescription)}
          type="submit"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default RefundCheckbox;
