/* eslint-disable import/prefer-default-export */
import { GrowthBook } from '@growthbook/growthbook-react';
import { LocalStorageStickyBucketService } from '@growthbook/growthbook';

export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.REACT_APP_GROWTHBOOK_KEY,
  enableDevMode: process.env.REACT_APP_TEMP_MEE_ENV === 'development',
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    console.log('Experiment Viewed', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
  stickyBucketService: new LocalStorageStickyBucketService(),
});

export const setJobAttributes = async (job) => {
  await growthbook.setAttributes({
    ...growthbook.getAttributes(),
    job: {
      state: job?.state,
    },
  });
};

export const showClockInStatesW2 = () => growthbook.getFeatureValue('show-clock-in-out_states-w2');
export const getShouldAllowAdminToPostProcedure = () =>
  growthbook.isOn('should-allow-admin-to-post-procedure');
export const getShouldUseMetricsService = () => growthbook.isOn('use-metrics-service');
export const shouldUseNewOfferExpiration = () => growthbook.isOn('use-new-offer-expiration');
export const getShouldShowWhoCanSeeThisShift = () =>
  growthbook.isOn('should-show-who-can-see-this-shift');
