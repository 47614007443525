import React from 'react';

const CustomButton = (props) => {
  let margin_top;
  props.margin === true ? (margin_top = 25) : (margin_top = 0);
  const Styles = {
    button: {
      width: props.width,
      height: props.height ? props.height : 50,
      border: 'none',
      borderRadius: 25,
      backgroundColor: props.color,
      marginTop: margin_top,
      display: 'flex',
      paddingLeft: props.name === 'Confirmed/Scheduled' ? 10 : 40,
      paddingRight: 20,
      outline: 'none',
      alignItems: 'center',
    },
    button2: {
      width: props.width,
      height: props.height ? props.height : 50,
      border: 'none',
      borderRadius: 25,
      backgroundColor: props.color,
      marginTop: margin_top,
      outline: 'none',
    },
    p: {
      color: '#FFFFFF',
      fontFamily: 'Nunito, sans-serif',
      fontSize:
        props.name === 'Confirmed/Scheduled' && props.image
          ? 12
          : props.fontSize
            ? props.fontSize
            : 18,
      fontWeight: 'bold',
      margin: 0,
      textAlign: 'center',
    },
  };

  if (props.image) {
    let btnStyles = {};
    props.name === 'Confirmed/Scheduled'
      ? (btnStyles = { padding: '0px 5px 0px 0px' })
      : (btnStyles = { padding: '0px 15px 0px 0px' });
    return (
      <button
        className={props.class_name}
        style={Styles.button}
        onClick={(_) => props.click()}
        disabled={!!props.btnDisabled}
      >
        {props.image ? <img src={props.image} alt="icon" style={btnStyles} /> : ''}{' '}
        <p style={Styles.p}>{props.name}</p>
      </button>
    );
  }
  return (
    <button
      className={props.class_name}
      style={Styles.button2}
      onClick={(_) => props.click()}
      disabled={!!props.btnDisabled}
    >
      <p style={Styles.p}>{props.name}</p>
    </button>
  );
};

export default CustomButton;
