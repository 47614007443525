import { shouldUseNewOfferExpiration } from './growthbook';

export const breakTimes = ['00', '15', '30', '45', '60', '75', '90', '120', '180'];

export const lunchBreakItemsWithSuffix = [
  '0 min',
  '15 min',
  '30 min',
  '45 min',
  '1 hr',
  '1.5 hr',
  '2 hr',
];

export const useNotificationTypeMap = () => {
  const useNewOfferExpiration = shouldUseNewOfferExpiration();

  return {
    OFFICE_EVENT_OFFER_RECEIVED: {
      title: 'Candidate Applied',
      description:
        'These are notifications to let you know when a professional applies for one of your shifts at the listed rate.',
      isPush: true,
      isText: true,
      isEmail: true,
      isAlertAvailable: true,
    },
    OFFICE_EVENT_COUNTER_OFFER_RECEIVED: {
      title: 'Candidate Applied with Counter-Offer',
      description:
        'These are notifications to let you know when a professional applies for one of your shifts and is requesting a higher rate than what was listed.',
      isPush: true,
      isText: false,
      isEmail: true,
      isAlertAvailable: true,
    },
    OFFICE_EVENT_SINGLE_SHIFT_JOB_POSTED: {
      title: 'Shift Posting Confirmation (Single-Day Shift)',
      description:
        'These are notifications to let you know that your single shift request has been listed successfully.',
      isPush: false,
      isText: false,
      isEmail: true,
      isAlertAvailable: false,
    },
    OFFICE_EVENT_MULTI_DAY_JOB_POSTED: {
      title: 'Shifts Posting Confirmation (Multi-Day Booking)',
      description:
        'These are notifications to let you know that your multi-day booking request has been listed successfully.',
      isPush: false,
      isText: false,
      isEmail: true,
      isAlertAvailable: false,
    },
    OFFICE_EVENT_COUNTER_OFFER_EXPIRATION_WARNING_15_MINUTES: {
      title: 'Offer Expiration Warning (15 minutes)',
      description:
        'These are notifications to let you know when a counter offer is close to expiration.',
      isPush: true,
      isText: true,
      isEmail: true,
      isAlertAvailable: false,
      hidden: useNewOfferExpiration,
    },
    OFFICE_EVENT_JOB_CANCELLED_BY_PROFESSIONAL: {
      title: 'Shift Cancelled by Professional',
      description:
        'These are notifications to let you know when a confirmed professional has cancelled a shift, and whether it was re-posted with auto-confirm.',
      isPush: true,
      isText: true,
      isEmail: true,
      isAlertAvailable: true,
    },
    OFFICE_EVENT_CANCELLED_JOB_REFILLED: {
      title: 'Candidate Auto-Confirmed for a Shift',
      description:
        'These are notifications to let you know when a candidate was auto-confirmed for your shift, or when a re-posted job has been re-filled by a professional.',
      isPush: true,
      isText: true,
      isEmail: false,
      isAlertAvailable: true,
    },
    OFFICE_EVENT_MESSAGE_RECEIVED: {
      title: 'New Chat received from Professional',
      description:
        'These are notifications to let you know when a professional directly messages you within the app.',
      isPush: true,
      isText: true,
      isEmail: false,
      isAlertAvailable: false,
    },
    OFFICE_EVENT_COUNTER_OFFER_EXPIRATION_WARNING_1_HOUR: {
      title: 'Offer Expiration Warning (60 minutes)',
      description:
        'These are notifications to let you know when a counter offer is 1 hour away from expiring.',
      isPush: true,
      isText: true,
      isEmail: true,
      isAlertAvailable: false,
      hidden: useNewOfferExpiration,
    },
    OFFICE_EVENT_SHIFT_ENDED: {
      title: 'Shift Ended',
      description:
        'These are notifications that remind you to submit an adjustment (if needed) and review the professional.',
      isPush: true,
      isText: false,
      isEmail: true,
      isAlertAvailable: false,
    },
    OFFICE_EVENT_SHIFT_ADJUSTED_BY_PROFESSIONAL: {
      title: 'Professional Submits a Shift Adjustment',
      description:
        'These are notifications to let you know that a professional has submit an adjustment request.',
      isPush: true,
      isText: false,
      isEmail: false,
      isAlertAvailable: true,
    },
    OFFICE_SETTING_ALLOW_MARKETING_OUTREACH: {
      title: 'GoTu News & Updates',
      description:
        'These are notifications that send you latest updates from GoTu, including product updates, events, and more.',
      isPush: false,
      isText: true,
      isEmail: true,
      isAlertAvailable: false,
    },
    OFFICE_EVENT_OFFER_EXPIRATION: {
      title: 'Offer Expiration Warning',
      description:
        'These are notifications to let you know how many outstanding offers will expire today.',
      isPush: true,
      isText: true,
      isEmail: true,
      isAlertAvailable: false,
      hidden: !useNewOfferExpiration,
    },
  };
};

export const medMalStatus = {
  not_started: {
    code: 'not started',
    description: 'Not Started',
  },
  pending: {
    code: 'pending',
    description: 'Pending',
  },
  verified: {
    code: 'verified',
    description: 'Verified',
  },
  declined: {
    code: 'declined',
    description: 'Declined',
  },
};

export const professionSkillMap = {
  RDH: 'category-hyg-specialty',
  DA: 'category-da-specialty',
  DN: 'category-dn-experienced-procedures',
};

export const officeTypes = [
  { title: 'Private Practice (Independent Office)', value: 'private_practice_independent' },
  { title: 'Private Practice (Part of a Multi-Office Group)', value: 'private_practice_multi' },
  { title: 'DSO', value: 'dso' },
];

export const notificationTypeMapHYG = {
  WORKING_DAYS: [
    {
      key: 'monday',
      title: 'Monday',
    },
    {
      key: 'tuesday',
      title: 'Tuesday',
    },
    {
      key: 'wednesday',
      title: 'Wednesday',
    },
    {
      key: 'thursday',
      title: 'Thursday',
    },
    {
      key: 'friday',
      title: 'Friday',
    },
    {
      key: 'saturday',
      title: 'Saturday',
    },
    {
      key: 'sunday',
      title: 'Sunday',
    },
  ],
  SHIFT_TYPES: [
    {
      key: 'employee',
      title: 'Employee Shifts',
    },
    {
      key: 'contractor',
      title: 'Contractor Shifts',
    },
  ],
};

export const requiredCredentialOptions = [
  { label: 'RDH', value: 'RDH' },
  { label: 'DA', value: 'DA' },
  { label: 'DN', value: 'DN' },
];

export const userJobStatusFilterOpts = [
  { value: 'all', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'open', label: 'Open' },
  { value: 'pending', label: 'Pending' },
  { value: 'counter', label: 'Counter' },
  { value: 'filled', label: 'Filled' },
  { value: 'pay-out', label: 'Pay Out' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'expired', label: 'Expired' },
  { value: 'complete', label: 'Complete' },
];

export const userJobProfessionFilterOpts = [
  { value: 'all', label: 'All' },
  { value: 'RDH', label: 'RDH' },
  { value: 'DA', label: 'DA' },
  { value: 'DN', label: 'DN' },
];

export const userDaSubProfessions = [{ code: 'EFDA', name: 'Expanded Functions DA' }];

export const professionsWithSubProfessionsTable = {
  RDH: [],
  DA: [{ name: 'Expanded Functions DA', code: 'EFDA' }],
  DN: [],
  FD: [],
};

export const jobDetailScreenTab = {
  ACCEPTED_AND_COUNTERS: 'acceptedAndCounters',
  INVITES: 'invites',
  UNAVAILABLE: 'unavailable',
  CLICKED: 'clicked',
  SEEN: 'seen',
  EDITS: 'edits',
  ADJUSTMENTS: 'adjustments',
  TRANSACTIONS: 'transactions',
  RATING: 'rating',
  CLOCK_IN_OUT: 'clockInOut',
  VIEWS: 'views',
};
