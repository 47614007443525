import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchUsers } from '../../../../actions/userActions';
import { createLoadingSelector } from '../../../../apis/selectors';
import history from '../../../../history';
import Styles from '../../../../themes/style.module.scss';
import { getQueryParam, setQueryParams, updateQueryParam } from '../../../../utils';
import { Table } from '../../../commonComponents/Table/Table';
import { userListColumn } from '../../../commonComponents/user-column';
import { filterKeys } from '../utils';
import { CustomHeader } from './CustomHeader';

const ROWS_PER_PAGE = 15;

const initialActions = ['GET_ADMIN_RAM', 'GET_DSO_NAMES'];
const initialLoadingSelector = createLoadingSelector(initialActions);

const actions = ['FETCH_DENTIST', 'FETCH_DSO', 'FETCH_HYGIENIST', 'FETCH_UNFINISHED_USERS'];
const loadingSelector = createLoadingSelector(actions);

const useFilterState = (searchParams) => {
  const initialFilterState = filterKeys.reduce((acc, key) => {
    acc[key] =
      searchParams?.[key] ??
      getQueryParam(key) ??
      (key === 'page' ? 1 : key === 'order' ? 'desc' : '');
    return acc;
  }, {});

  return useState(initialFilterState);
};

export const CustomTable = ({
  title,
  data,
  count,
  searchParams,
  params,
  conditionalRowStyles,
  CustomFilters,
  omitColumns = [],
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const initialLoading = useSelector((state) => initialLoadingSelector(state));
  const isLoading = useSelector((state) => loadingSelector(state));
  const [filter, setFilter] = useFilterState(searchParams);
  const totalPages = Math.ceil(count / ROWS_PER_PAGE);

  const handleFilterChange = (type, value) => {
    updateQueryParam(type, value);
    setFilter((prevState) => ({ ...prevState, [type]: value, page: 1 }));
  };

  const handleTableChange = (page) => {
    setFilter((prevState) => ({ ...prevState, page }));
    updateQueryParam('page', page);
  };

  const handleOnSort = (selectedColumn, sortDirection) => {
    const sortObj = {
      page: filter.page,
      sortField: selectedColumn.sortField,
      order: sortDirection,
    };
    setQueryParams(sortObj, filterKeys);
    setFilter((prevState) => ({
      ...prevState,
      ...sortObj,
    }));
  };

  useEffect(() => {
    dispatch(
      fetchUsers({
        ...filter,
        ...params,
      }),
    );
  }, [filter, dispatch, params]);

  useEffect(() => {
    setQueryParams(filter, filterKeys);
  }, [filter, location]);

  const onRowClicked = (row) => {
    if (row.user_type === 'DNT') {
      history.push(`/user/dentist/${row._id}`);
    } else {
      history.push(`/user/hygienist/${row._id}`);
    }
  };

  const getColumn = () => userListColumn();

  if (initialLoading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
            marginLeft: 20,
            marginTop: 10,
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        overflowY: 'auto',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 8,
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
          position: 'absolute',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <h3 className={Styles.h3} style={{ fontSize: '24px', textAlign: 'center' }}>
            {title}
          </h3>
          {isLoading && (
            <div>
              <Spinner
                animation="border"
                role="status"
                variant="info"
                style={{
                  height: 30,
                  width: 30,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div style={{ width: '90%', paddingTop: 60 }}>
        <CustomHeader
          filter={filter}
          handleFilterChange={handleFilterChange}
          userType={params.user_type}
          CustomFilters={CustomFilters}
        />
        <Table
          filter={filter}
          initialPage={getQueryParam('page')}
          columns={getColumn()?.filter((column) => !omitColumns.includes(column.name))}
          data={data}
          noDataComponent="There are no users"
          isLoading={isLoading}
          totalPages={totalPages}
          paginationPerPage={ROWS_PER_PAGE}
          paginationTotalRows={count}
          onSort={handleOnSort}
          onRowClicked={onRowClicked}
          onPageChange={handleTableChange}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </div>
  );
};
