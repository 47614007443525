import React from 'react';

export default ({ didSelect, value, style }) => {
  const abraviations = states.map((state) => state.abbreviation);

  return (
    <select
      className="custom-select custom-select-lg"
      onChange={(event) => didSelect(event.target.value)}
      value={value}
      style={style}
    >
      <option key="all" value="">
        All
      </option>
      {abraviations.map((key) => (
        <option key={key} value={key}>
          {key}
        </option>
      ))}
    </select>
  );
};

export const states = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
    licVerificationUrl: 'https://bdeal.igovsolution.net/Online/Lookups/Individual_Lookup.aspx',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
    licVerificationUrl: 'https://www.commerce.alaska.gov/cbp/main/Search/Professional',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
    licVerificationUrl:
      'https://azbodprod.glsuite.us/GLSuiteWeb/clients/azbod/public/WebVerificationSearch.aspx',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
    licVerificationUrl:
      'https://search.statesolutions.us/?A=ASBDE&AID=RS&GUID=20C2E912D0C849AB9ADC2353CD5970EB',
  },
  {
    name: 'California',
    abbreviation: 'CA',
    licVerificationUrl: 'https://search.dca.ca.gov/results',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
    licVerificationUrl: 'https://apps.colorado.gov/dora/licensing/Lookup/LicenseLookup.aspx',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
    licVerificationUrl: 'https://www.elicense.ct.gov/Lookup/DownloadRoster.aspx',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
    licVerificationUrl: 'https://delpros.delaware.gov/OH_HomePage',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
    licVerificationUrl: 'https://doh.force.com/ver/s/',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
    licVerificationUrl: 'https://appsmqa.doh.state.fl.us/MQASearchServices/HealthCareProviders',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
    licVerificationUrl: 'https://gadch.mylicense.com/verification/Search.aspx',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
    licVerificationUrl: 'https://pvl.ehawaii.gov/pvlsearch/license/DH-1001',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
    licVerificationUrl: 'https://elitepublic.isbd.idaho.gov/IBODPublic/LPRBrowser.aspx',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
    licVerificationUrl: 'https://ilesonline.idfpr.illinois.gov/DFPR/Lookup/LicenseLookup.aspx',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
    licVerificationUrl: 'https://mylicense.in.gov/EVerification/Search.aspx',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
    licVerificationUrl:
      'https://eservices.iowa.gov/PublicPortal/Iowa/IDB/licenseQuery/LicenseQuery.jsp',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
    licVerificationUrl: 'https://www.kansas.gov/dental-verification/search.do',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
    licVerificationUrl: 'https://secure.kentucky.gov/formservices/KYBD/HygienistSearch/search',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
    licVerificationUrl: 'https://www.membersbase.com/lsbdweb/licenseverification.htm',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
    licVerificationUrl: 'https://www.pfr.maine.gov/ALMSOnline/ALMSQuery/SearchResults.aspx',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
    licVerificationUrl: 'https://mdbod.mylicense.com/Verification/Search.aspx',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
    licVerificationUrl: 'https://checkahealthlicense.mass.gov/',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
    licVerificationUrl:
      'https://aca3.accela.com/MILARA/GeneralProperty/PropertyLookUp.aspx?isLicensee=Y&TabName=APO',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
    licVerificationUrl: 'https://mn.gov/boards/dentistry/verify/',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
    licVerificationUrl: 'https://onlineportal.dentalboard.ms.gov/portal/license-search',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
    licVerificationUrl: 'https://pr.mo.gov/licensee-search-division.asp',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
    licVerificationUrl: 'https://ebizws.mt.gov/PUBLICPORTAL/searchform?mylist=licenses',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
    licVerificationUrl: 'https://www.nebraska.gov/LISSearch/search.cgi',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
    licVerificationUrl: 'https://online.nvdental.org/#/VerifyLicense',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
    licVerificationUrl: 'https://forms.nh.gov/licenseverification/SearchResults.aspx',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
    licVerificationUrl: 'https://newjersey.mylicense.com/verification/Search.aspx?facility=N',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
    licVerificationUrl: 'http://verification.rld.state.nm.us/',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
    licVerificationUrl: 'http://www.op.nysed.gov/opsearches.htm',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
    licVerificationUrl: 'https://www.ncdentalboard.org/license_verification.htm',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
    licVerificationUrl: 'https://www.nddentalboard.org/verify/',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
    licVerificationUrl:
      'https://elicense.ohio.gov/oh_verifylicense?board=Dental+Board&firstName=&lastName=&licenseNumber=&searchType=individual',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
    licVerificationUrl: 'https://www.ok.gov/dentistry/License_Verification/index.html',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
    licVerificationUrl: 'https://online.oregondentistry.org/#/verifylicense',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
    licVerificationUrl: 'https://www.pals.pa.gov/#/page/search',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
    licVerificationUrl: 'https://healthri.mylicense.com/Verification/',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
    licVerificationUrl: 'https://verify.llronline.com/LicLookup/Dent/Dent.aspx?div=34',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
    licVerificationUrl: 'https://www.sdboardofdentistry.org/verify.asp',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
    licVerificationUrl: 'https://apps.health.tn.gov/licensure/default.aspx',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
    licVerificationUrl: 'https://ls.tsbde.texas.gov/hyg-base.php',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
    licVerificationUrl: 'https://secure.utah.gov/llv/search/index.html',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
    licVerificationUrl:
      'https://secure.professionals.vermont.gov/prweb/PRServletCustom/V9csDxL3sXkkjMC_FR2HrA%5B%5B*/!STANDARD?UserIdentifier=LicenseRoasterguestuser',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
    licVerificationUrl: 'https://dhp.virginiainteractive.org/Lookup/Result',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
    licVerificationUrl: 'https://fortress.wa.gov/doh/providercredentialsearch/',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
    licVerificationUrl:
      'https://wvbodprod.glsuite.us/GLSuiteWeb/Clients/WVBOD/Public/Verification/Search.aspx',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
    licVerificationUrl: 'https://licensesearch.wi.gov/',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
    licVerificationUrl:
      'https://docs.google.com/spreadsheets/d/17hcykPSScZ_h3mv1ykKb-yZN7w22mOwiYU4I4CSbFkI/edit#gid=0',
  },
];
