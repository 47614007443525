import { METRICS_ACTION } from './actionTypes';
import { getAuthHeaders } from './authActions';
import tempMee from '../apis/tempMee';

export const fetchProfessionalBadges = (userId) => async (dispatch) => {
  try {
    dispatch({ type: METRICS_ACTION.FETCH_HYG_BADGES_REQUEST });

    const headers = await getAuthHeaders();
    const response = await tempMee.get(
      `metrics-service/admin/professional-badges?professionalIds=${userId}`,
      {
        headers,
      },
    );

    const data = response?.data?.data?.[0]?.badges || [];

    dispatch({ type: METRICS_ACTION.FETCH_HYG_BADGES_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: METRICS_ACTION.FETCH_HYG_BADGES_FAILURE, payload: err });
  }
};

export const fetchProfessionalLoyaltyLevel = (userIds = []) => async (dispatch) => {
  try {
    dispatch({ type: METRICS_ACTION.FETCH_HYG_LOYALTY_LEVEL_REQUEST });

    const headers = await getAuthHeaders();
    const response = await tempMee.get(
      `metrics-service/admin/professional-loyalty`,
      {
        headers,
        params: {
          professionalIds: [userIds].join(','),
          sortDirection: 'asc'
        }
      },
    );

    const loyaltyResponse = response.data?.data;

    dispatch({ type: METRICS_ACTION.FETCH_HYG_LOYALTY_LEVEL_SUCCESS, payload: loyaltyResponse });
  } catch (err) {
    dispatch({ type: METRICS_ACTION.FETCH_HYG_LOYALTY_LEVEL_FAILURE, payload: err });
  }
};
