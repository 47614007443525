import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from 'react-bootstrap';
import {
  addFavoriteUser,
  deleteFavoriteUser,
  fetchUserFavoritesList,
} from '../../actions/userActions';
import removeIcon from '../../images/close-cross.png';
import CustomPortal from './CustomPortal';
import { createLoadingSelector } from '../../apis/selectors';
import UserSearchField from '../mainComponents/Job/UserSearchField';
import history from '../../history';

export default function FavoritesList() {
  const dispatch = useDispatch();

  const [showRemoveConfirmPopup, setShowRemoveConfirmPopup] = useState(false);
  const [removeId, setRemoveId] = useState(null);

  const favoritesList = useSelector((state) => state.users.currentUser?.favoritesList);
  const allProfessions = useSelector((state) => state.jobs.allProfessions);

  const loadingSelector = createLoadingSelector(['FETCH_USER_FAVORITES_LIST']);
  const isLoading = useSelector((state) => loadingSelector(state));

  useEffect(() => {
    dispatch(fetchUserFavoritesList());
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
            marginLeft: 20,
            marginTop: 10,
          }}
        />
      </div>
    );
  }

  const onSelectUser = (user) => {
    dispatch(addFavoriteUser(user?.id));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        padding: '0px 90px',
        marginTop: '50px',
      }}
    >
      <div style={{ width: '100%' }}>
        <h4 className="bold global_font">Add Hygienist/Dental Assistant:</h4>
        <UserSearchField
          user_type="HYG"
          onSelectUser={onSelectUser}
          placeholder="Type a Name/Last Name"
          style={{ width: '30%', alignItems: 'flex-start', marginTop: 12 }}
        />
      </div>

      <table style={{ width: '100%' }}>
        <tr style={{ backgroundColor: '#ADD8E6' }}>
          <th style={{ padding: 15, fontSize: 16, textAlign: 'center' }}>Action</th>
          <th style={{ padding: 15, fontSize: 16, textAlign: 'center' }}> Name</th>
          <th style={{ padding: 15, fontSize: 16, textAlign: 'center' }}>Profession</th>
          <th style={{ padding: 15, fontSize: 16, textAlign: 'center' }}>Remove</th>
        </tr>

        {favoritesList?.map(({ id, fullname, professions }) => (
          <tr style={{ border: '1px solid #efefef', textAlign: 'center' }}>
            <td style={{ padding: 15, fontSize: 15 }}>
              <p
                style={{ cursor: 'pointer', color: '#3eb0ca', textDecorationLine: 'underline' }}
                onClick={() => history.push(`/user/hygienist/${id}`)}
              >
                View Profile
              </p>
            </td>
            <td style={{ padding: 15, fontSize: 15 }}>{fullname}</td>
            <td style={{ padding: 15, fontSize: 15 }}>
              {allProfessions?.find((profession) => profession?.code === professions[0])?.name}
            </td>
            <td style={{ padding: 15, fontSize: 15 }}>
              <img
                onClick={() => {
                  setShowRemoveConfirmPopup(true);
                  setRemoveId(id);
                }}
                src={removeIcon}
                style={{ width: 15, height: 15, cursor: 'pointer' }}
                alt="remove_icon"
              />
            </td>
          </tr>
        ))}
      </table>
      {!favoritesList?.length && (
        <div
          style={{
            border: '1px solid #efefef',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: '20px 0px',
          }}
        >
          <h3 className="bold global_font">No favorites available</h3>
        </div>
      )}

      {showRemoveConfirmPopup && (
        <CustomPortal
          title="Are you sure you want to remove this professional?"
          submitText="Delete"
          cancelText="Cancel"
          submitPressed={() => {
            dispatch(deleteFavoriteUser(removeId));

            setShowRemoveConfirmPopup(false);
            setRemoveId(null);
          }}
          cancelPressed={() => {
            setShowRemoveConfirmPopup(false);
            setRemoveId(null);
          }}
        />
      )}
    </div>
  );
}
