import dateFormat from 'dateformat';
import moment from 'moment';
import React from 'react';
import { UserProfession } from '../../enums/UserProfession';
import '../../themes/modalWindow.scss';

const ModalJobEdit = ({
  job_detail,
  editRate,
  editOfferStartTime,
  editOfferEndTime,
  editOfferDate,
  editIsLunchBreakPaid,
  editLunchBreakTime,
  editIsAssistedHygiene,
  editSkills,
  editAutoFill,
  editAutoFillFavorites,
  editAutoFillHighlyRated,
  onSave,
  closeModal,
  procedures,
}) => (
  <div className="modal_window" style={{ height: 550, overflowY: 'scroll' }}>
    <div className="modal_title">
      <p>Are you sure?</p>
    </div>
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%', marginRight: '10px' }}>
        <p className="title_column">Before the update</p>
        <div className="modal_column">
          <p className="modal_text">Date</p>
          <p>{job_detail?.local_date || ''}</p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Time</p>
          <p>{`${job_detail.local_start_time} - ${job_detail.local_end_time}`}</p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Rate</p>
          <p>{Number.isInteger(job_detail.rate) ? job_detail.rate?.toFixed(2) : job_detail.rate}</p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Meal Break Paid</p>
          <p>{job_detail.is_lunch_break_paid ? 'Yes' : 'No'}</p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Meal Break Time</p>
          <p>{job_detail.lunch_break_time}</p>
        </div>
        {job_detail?.profession === UserProfession.RDH && (
          <div className="modal_column">
            <p className="modal_text">Assisted Hygiene Available</p>
            <p>{job_detail.isAssistedHygiene ? 'Yes' : 'No'}</p>
          </div>
        )}
        <div className="modal_column">
          <p className="modal_text">Auto-Confirm (Favorites)</p>
          <p>{job_detail.autofillOptions?.favorite ? 'On' : 'Off'}</p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Auto-Confirm (Highly-Rated)</p>
          <p>{job_detail.autofillOptions?.highlyRated ? 'On' : 'Off'}</p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Auto-Confirm (All Eligible)</p>
          <p>{job_detail.autoFill ? 'On' : 'Off'}</p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Skills</p>
          <p>{job_detail.specialty?.split('_')?.[1] || '-'}</p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Shift Procedure</p>
          <p>
            {job_detail.procedures?.length > 0
              ? job_detail.procedures
                .map((procedure) => procedure.replace(/^procedure_/g, ' '))
                .join(', ')
              : '-'}
          </p>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <p className="title_column">Updated</p>
        <div className="modal_column">
          <p className="modal_text">Date</p>
          <p>{dateFormat(editOfferDate, 'ddd, mmmm dd, yyyy')}</p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Time</p>
          <p>
            {`${moment(editOfferStartTime, 'hh:mm A').format('hh:mm a')} - ${moment(
              editOfferEndTime,
              'hh:mm A',
            ).format('hh:mm a')}`}
          </p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Rate</p>
          <p className="modal_text">
            {Number.isInteger(editRate) ? editRate?.toFixed(2) : editRate}
          </p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Meal Break Paid</p>
          <p>{editIsLunchBreakPaid}</p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Meal Break Time</p>
          <p>{editLunchBreakTime}</p>
        </div>
        {job_detail?.profession === UserProfession.RDH && (
          <div className="modal_column">
            <p className="modal_text">Assisted Hygiene Available</p>
            <p>{editIsAssistedHygiene}</p>
          </div>
        )}
        <div className="modal_column">
          <p className="modal_text">Auto-Confirm (Favorites)</p>
          <p>{editAutoFillFavorites}</p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Auto-Confirm (Highly-Rated)</p>
          <p>{editAutoFillHighlyRated}</p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Auto-Confirm (All Eligible)</p>
          <p>{editAutoFill}</p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Skills</p>
          <p>{editSkills?.split('_')?.[1] || '-'}</p>
        </div>
        <div className="modal_column">
          <p className="modal_text">Shift Procedure</p>
          <p>
            {procedures?.length > 0
              ? procedures.map((procedure) => procedure.replace(/^procedure_/g, ' ')).join(', ')
              : '-'}
          </p>
        </div>
      </div>
    </div>

    <div className="modal_button">
      <button type="button" className="cancel" onClick={() => closeModal()}>
        Cancel
      </button>
      <button type="button" className="confirm" onClick={onSave}>
        Confirm
      </button>
    </div>
  </div>
);

export default ModalJobEdit;
