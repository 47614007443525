export const BLOCK_HYG_REQUEST = 'BLOCK_HYG_REQUEST';
export const BLOCK_HYG_SUCCESS = 'BLOCK_HYG_SUCCESS';
export const BLOCK_HYG_FAILURE = 'BLOCK_HYG_FAILURE';

export const UNBLOCK_HYG_REQUEST = 'UNBLOCK_HYG_REQUEST';
export const UNBLOCK_HYG_SUCCESS = 'UNBLOCK_HYG_SUCCESS';
export const UNBLOCK_HYG_FAILURE = 'UNBLOCK_HYG_FAILURE';

export const FETCH_UNFINISHED_USERS_REQUEST = 'FETCH_UNFINISHED_USERS_REQUEST';
export const FETCH_UNFINISHED_USERS_SUCCESS = 'FETCH_UNFINISHED_USERS_SUCCESS';
export const FETCH_UNFINISHED_USERS_FAILURE = 'FETCH_UNFINISHED_USERS_FAILURE';

export const FETCH_HYGIENIST_REQUEST = 'FETCH_HYGIENIST_REQUEST';
export const FETCH_HYGIENIST_SUCCESS = 'FETCH_HYGIENIST_SUCCESS';
export const FETCH_HYGIENIST_FAILURE = 'FETCH_HYGIENIST_FAILURE';

export const FETCH_DENTIST_REQUEST = 'FETCH_DENTIST_REQUEST';
export const FETCH_DENTIST_SUCCESS = 'FETCH_DENTIST_SUCCESS';
export const FETCH_DENTIST_FAILURE = 'FETCH_DENTIST_FAILURE';

export const FETCH_DSO_REQUEST = 'FETCH_DSO_REQUEST';
export const FETCH_DSO_SUCCESS = 'FETCH_DSO_SUCCESS';
export const FETCH_DSO_FAILURE = 'FETCH_DSO_FAILURE';

export const FETCH_CHILD_OFFICES_SUCCESS = 'FETCH_CHILD_OFFICES_SUCCESS';
export const FETCH_CHILD_OFFICES_REQUEST = 'FETCH_CHILD_OFFICES_REQUEST';
export const FETCH_CHILD_OFFICES_FAILURE = 'FETCH_CHILD_OFFICES_FAILURE';

export const DELETE_CHILD_OFFICE_REQUEST = 'DELETE_CHILD_OFFICE_REQUEST';
export const DELETE_CHILD_OFFICE_SUCCESS = 'DELETE_CHILD_OFFICE_SUCCESS';
export const DELETE_CHILD_OFFICE_FAILURE = 'DELETE_CHILD_OFFICE_FAILURE';

export const ADD_CHILD_OFFICE_REQUEST = 'ADD_CHILD_OFFICE_REQUEST';
export const ADD_CHILD_OFFICE_SUCCESS = 'ADD_CHILD_OFFICE_SUCCESS';
export const ADD_CHILD_OFFICE_FAILURE = 'ADD_CHILD_OFFICE_FAILURE';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';

export const FETCH_CURRENT_USER_STRIPE_ID = 'FETCH_CURRENT_USER_STRIPE_ID';

export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE';

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';

export const UPDATE_CURRENT_USER_SUCCESS = 'UPDATE_CURRENT_USER_SUCCESS';
export const UPDATE_CURRENT_USER_REQUEST = 'UPDATE_CURRENT_USER_REQUEST';
export const UPDATE_CURRENT_USER_FAILURE = 'UPDATE_CURRENT_USER_FAILURE';

export const UPDATE_CURRENT_USER_PAYMENT_SUCCESS = 'UPDATE_CURRENT_USER_PAYMENT_SUCCESS';
export const UPDATE_CURRENT_USER_PAYMENT_REQUEST = 'UPDATE_CURRENT_USER_PAYMENT_REQUEST';
export const UPDATE_CURRENT_USER_PAYMENT_FAILURE = 'UPDATE_CURRENT_USER_PAYMENT_FAILURE';

export const UPDATE_RAM_VERIFIED_STATUS_SUCCESS = 'UPDATE_RAM_VERIFIED_STATUS_SUCCESS';
export const UPDATE_RAM_VERIFIED_STATUS_REQUEST = 'UPDATE_RAM_VERIFIED_STATUS_REQUEST';
export const UPDATE_RAM_VERIFIED_STATUS_FAILURE = 'UPDATE_RAM_VERIFIED_STATUS_FAILURE';

export const UPDATE_USER_REFERRAL_TYPE_SUCCESS = 'UPDATE_USER_REFERRAL_TYPE_SUCCESS';
export const UPDATE_USER_REFERRAL_TYPE_REQUEST = 'UPDATE_USER_REFERRAL_TYPE_REQUEST';
export const UPDATE_USER_REFERRAL_TYPE_FAILURE = 'UPDATE_USER_REFERRAL_TYPE_FAILURE';

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const UPDATE_USER_AVATAR_SUCCESS = 'UPDATE_USER_AVATAR_SUCCESS';
export const UPDATE_USER_AVATAR_REQUEST = 'UPDATE_USER_AVATAR_REQUEST';
export const UPDATE_USER_AVATAR_FAILURE = 'UPDATE_USER_AVATAR_FAILURE';

export const FETCH_USER_NOTES_SUCCESS = 'FETCH_USER_NOTES_SUCCESS';
export const FETCH_USER_NOTES_REQUEST = 'FETCH_USER_NOTES_REQUEST';
export const FETCH_USER_NOTES_FAILURE = 'FETCH_USER_NOTES_FAILURE';

export const ADD_USER_NOTE_SUCCESS = 'ADD_USER_NOTE_SUCCESS';
export const ADD_USER_NOTE_REQUEST = 'ADD_USER_NOTE_REQUEST';
export const ADD_USER_NOTE_FAILURE = 'ADD_USER_NOTE_FAILURE';

export const ADD_SHIFT_NOTE_SUCCESS = 'ADD_SHIFT_NOTE_SUCCESS';
export const ADD_SHIFT_NOTE_REQUEST = 'ADD_SHIFT_NOTE_REQUEST';
export const ADD_SHIFT_NOTE_FAILURE = 'ADD_SHIFT_NOTE_FAILURE';

export const FETCH_SHIFT_NOTES_SUCCESS = 'FETCH_SHIFT_NOTES_SUCCESS';
export const FETCH_SHIFT_NOTES_REQUEST = 'FETCH_SHIFT_NOTES_REQUEST';
export const FETCH_SHIFT_NOTES_FAILURE = 'FETCH_SHIFT_NOTES_FAILURE';

export const IMPORT_TO_TRINET_SUCCESS = 'IMPORT_TO_TRINET_SUCCESS';
export const IMPORT_TO_TRINET_REQUEST = 'IMPORT_TO_TRINET_REQUEST';
export const IMPORT_TO_TRINET_FAILURE = 'IMPORT_TO_TRINET_FAILURE';

export const TERMINATE_USER_SUCCESS = 'TERMINATE_USER_SUCCESS';
export const TERMINATE_USER_REQUEST = 'TERMINATE_USER_REQUEST';
export const TERMINATE_USER_FAILURE = 'TERMINATE_USER_FAILURE';

export const UPDATE_W9_SUCCESS = 'UPDATE_W9_SUCCESS';
export const UPDATE_W9_REQUEST = 'UPDATE_W9_REQUEST';
export const UPDATE_W9_FAILURE = 'UPDATE_W9_FAILURE';

export const SEND_OFFICE_RELEASE_FORM_SUCCESS = 'SEND_OFFICE_RELEASE_FORM_SUCCESS';
export const SEND_OFFICE_RELEASE_FORM_REQUEST = 'SEND_OFFICE_RELEASE_FORM_REQUEST';
export const SEND_OFFICE_RELEASE_FORM_FAILURE = 'SEND_OFFICE_RELEASE_FORM_FAILURE';

export const SEND_W9_SUCCESS = 'SEND_W9_SUCCESS';
export const SEND_W9_REQUEST = 'SEND_W9_REQUEST';
export const SEND_W9_FAILURE = 'SEND_W9_FAILURE';

export const DELETE_W9_SUCCESS = 'DELETE_W9_SUCCESS';
export const DELETE_W9_REQUEST = 'DELETE_W9_REQUEST';
export const DELETE_W9_FAILURE = 'DELETE_W9_FAILURE';

export const UPDATE_I9_SUCCESS = 'UPDATE_I9_SUCCESS';
export const UPDATE_I9_REQUEST = 'UPDATE_I9_REQUEST';
export const UPDATE_I9_FAILURE = 'UPDATE_I9_FAILURE';

export const UPDATE_USER_STATE_SUCCESS = 'UPDATE_USER_STATE_SUCCESS';
export const UPDATE_USER_STATE_REQUEST = 'UPDATE_USER_STATE_REQUEST';
export const UPDATE_USER_STATE_FAILURE = 'UPDATE_USER_STATE_FAILURE';

export const UPDATE_USER_AVAILABILITY_SUCCESS = 'UPDATE_USER_AVAILABILITY_SUCCESS';
export const UPDATE_USER_AVAILABILITY_REQUEST = 'UPDATE_USER_AVAILABILITY_REQUEST';
export const UPDATE_USER_AVAILABILITY_FAILURE = 'UPDATE_USER_AVAILABILITY_FAILURE';

export const ADD_NEW_ADDRESS_SUCCESS = 'ADD_NEW_ADDRESS_SUCCESS';
export const ADD_NEW_ADDRESS_REQUEST = 'ADD_NEW_ADDRESS_REQUEST';
export const ADD_NEW_ADDRESS_FAILURE = 'ADD_NEW_ADDRESS_FAILURE';

export const CREATE_EMPTY_USER_SUCCESS = 'CREATE_EMPTY_USER_SUCCESS';
export const CREATE_EMPTY_USER_REQUEST = 'CREATE_EMPTY_USER_REQUEST';
export const CREATE_EMPTY_USER_FAILURE = 'CREATE_EMPTY_USER_FAILURE';

export const DELETE_USER_ADDRESS_REQUEST = 'DELETE_USER_ADDRESS_REQUEST';
export const DELETE_USER_ADDRESS_FAILURE = 'DELETE_USER_ADDRESS_FAILURE';
export const DELETE_USER_ADDRESS_SUCCESS = 'DELETE_USER_ADDRESS_SUCCESS';

export const FETCH_USER_NOTIFICATION_SETTINGS_REQUEST = 'FETCH_USER_NOTIFICATION_SETTINGS_REQUEST';
export const FETCH_USER_NOTIFICATION_SETTINGS_FAILURE = 'FETCH_USER_NOTIFICATION_SETTINGS_FAILURE';
export const FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS = 'FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS';

export const UPDATE_USER_NOTIFICATION_SETTINGS_REQUEST =
  'UPDATE_USER_NOTIFICATION_SETTINGS_REQUEST';
export const UPDATE_USER_NOTIFICATION_SETTINGS_FAILURE =
  'UPDATE_USER_NOTIFICATION_SETTINGS_FAILURE';
export const UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS =
  'UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS';

export const FETCH_USER_NOTIFICATION_SETTINGS_HYG_REQUEST =
  'FETCH_USER_NOTIFICATION_SETTINGS_HYG_REQUEST';
export const FETCH_USER_NOTIFICATION_SETTINGS_HYG_FAILURE =
  'FETCH_USER_NOTIFICATION_SETTINGS_HYG_FAILURE';
export const FETCH_USER_NOTIFICATION_SETTINGS_HYG_SUCCESS =
  'FETCH_USER_NOTIFICATION_SETTINGS_HYG_SUCCESS';

export const UPDATE_USER_NOTIFICATION_SETTINGS_HYG_REQUEST =
  'UPDATE_USER_NOTIFICATION_SETTINGS_HYG_REQUEST';
export const UPDATE_USER_NOTIFICATION_SETTINGS_HYG_FAILURE =
  'UPDATE_USER_NOTIFICATION_SETTINGS_HYG_FAILURE';
export const UPDATE_USER_NOTIFICATION_SETTINGS_HYG_SUCCESS =
  'UPDATE_USER_NOTIFICATION_SETTINGS_HYG_SUCCESS';

export const FETCH_USER_FAVORITES_LIST_REQUEST = 'FETCH_USER_FAVORITES_LIST_REQUEST';
export const FETCH_USER_FAVORITES_LIST_FAILURE = 'FETCH_USER_FAVORITES_LIST_FAILURE';
export const FETCH_USER_FAVORITES_LIST_SUCCESS = 'FETCH_USER_FAVORITES_LIST_SUCCESS';

export const FETCH_USER_HIGHLY_RATED_PROFESSIONALS_LIST_REQUEST =
  'FETCH_USER_HIGHLY_RATED_PROFESSIONALS_REQUEST';
export const FETCH_USER_HIGHLY_RATED_PROFESSIONALS_LIST_FAILURE =
  'FETCH_USER_HIGHLY_RATED_PROFESSIONALS_FAILURE';
export const FETCH_USER_HIGHLY_RATED_PROFESSIONALS_LIST_SUCCESS =
  'FETCH_USER_HIGHLY_RATED_PROFESSIONALS_SUCCESS';

export const FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_REQUEST =
  'FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_REQUEST';
export const FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_FAILURE =
  'FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_FAILURE';
export const FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_SUCCESS =
  'FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_SUCCESS';

export const FETCH_USER_WORKER_CLASSIFICATION_REQUEST = 'FETCH_USER_WORKER_CLASSIFICATION_REQUEST';
export const FETCH_USER_WORKER_CLASSIFICATION_FAILURE = 'FETCH_USER_WORKER_CLASSIFICATION_FAILURE';
export const FETCH_USER_WORKER_CLASSIFICATION_SUCCESS = 'FETCH_USER_WORKER_CLASSIFICATION_SUCCESS';

export const SAVE_USER_WORKER_CLASSIFICATION_REQUEST = 'SAVE_USER_WORKER_CLASSIFICATION_REQUEST';
export const SAVE_USER_WORKER_CLASSIFICATION_FAILURE = 'SAVE_USER_WORKER_CLASSIFICATION_FAILURE';
export const SAVE_USER_WORKER_CLASSIFICATION_SUCCESS = 'SAVE_USER_WORKER_CLASSIFICATION_SUCCESS';

export const ADD_FAVORITE_USER_REQUEST = 'ADD_FAVORITE_USER_REQUEST';
export const ADD_FAVORITE_USER_FAILURE = 'ADD_FAVORITE_USER_FAILURE';
export const ADD_FAVORITE_USER_SUCCESS = 'ADD_FAVORITE_USER_SUCCESS';

export const DELETE_FAVORITE_USER_REQUEST = 'DELETE_FAVORITE_USER_REQUEST';
export const DELETE_FAVORITE_USER_FAILURE = 'DELETE_FAVORITE_USER_FAILURE';
export const DELETE_FAVORITE_USER_SUCCESS = 'DELETE_FAVORITE_USER_SUCCESS';

export const SEND_PUSH_NOTIFICATION_REQUEST = 'SEND_PUSH_NOTIFICATION_REQUEST';
export const SEND_PUSH_NOTIFICATION_FAILURE = 'SEND_PUSH_NOTIFICATION_FAILURE';
export const SEND_PUSH_NOTIFICATION_SUCCESS = 'SEND_PUSH_NOTIFICATION_SUCCESS';

export const SEND_USER_MFA_REQUEST = 'SEND_USER_MFA_REQUEST';
export const SEND_USER_MFA_FAILURE = 'SEND_USER_MFA_FAILURE';
export const SEND_USER_MFA_SUCCESS = 'SEND_USER_MFA_SUCCESS';

export const RESET_USER_PASSWORD_REQUEST = 'RESET_USER_PASSWORD_REQUEST';
export const RESET_USER_PASSWORD_FAILURE = 'RESET_USER_PASSWORD_FAILURE';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';

export const PATCH_HYGIENIST_NOTIFICATION = 'PATCH_HYGIENIST_NOTIFICATION';
export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
export const GOOGLE_PLACE_FETCH = 'GOOGLE_PLACE_FETCH';
export const LOADING_NOTIFICATION_SETTINGS_UPDATE = 'LOADING_NOTIFICATION_SETTINGS_UPDATE';

export const FETCH_JOBS = 'FETCH_JOBS';

export const SEND_PUSH_FOR_USERS_IN_RADIUS_REQUEST = 'SEND_PUSH_FOR_USERS_IN_RADIUS_REQUEST';
export const SEND_PUSH_FOR_USERS_IN_RADIUS_SUCCESS = 'SEND_PUSH_FOR_USERS_IN_RADIUS_SUCCESS';
export const SEND_PUSH_FOR_USERS_IN_RADIUS_FAILURE = 'SEND_PUSH_FOR_USERS_IN_RADIUS_FAILURE';

export const APPROVE_USER_BY_SMS = 'APPROVE_USER_BY_SMS';
export const APPROVE_USER_BY_EMAIL = 'APPROVE_USER_BY_EMAIL';

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_REQUEST = 'FETCH_JOBS_REQUEST';
export const FETCH_JOBS_FAILURE = 'FETCH_JOBS_FAILURE';

export const FETCH_OPEN_JOBS_SUCCESS = 'FETCH_OPEN_JOBS_SUCCESS';
export const FETCH_OPEN_JOBS_REQUEST = 'FETCH_OPEN_JOBS_REQUEST';
export const FETCH_OPEN_JOBS_FAILURE = 'FETCH_OPEN_JOBS_FAILURE';

export const GET_ALL_PROFESSIONS_SUCCESS = 'GET_ALL_PROFESSIONS_SUCCESS';
export const GET_ALL_PROFESSIONS_REQUEST = 'GET_ALL_PROFESSIONS_REQUEST';
export const GET_ALL_PROFESSIONS_FAILURE = 'GET_ALL_PROFESSIONS_FAILURE';

export const GET_ALL_SPECIALIZATIONS_SUCCESS = 'GET_ALL_SPECIALIZATIONS_SUCCESS';
export const GET_ALL_SPECIALIZATIONS_REQUEST = 'GET_ALL_SPECIALIZATIONS_REQUEST';
export const GET_ALL_SPECIALIZATIONS_FAILURE = 'GET_ALL_SPECIALIZATIONS_FAILURE';

export const GET_ALL_SOFTWARES_SUCCESS = 'GET_ALL_SOFTWARES_SUCCESS';
export const GET_ALL_SOFTWARES_REQUEST = 'GET_ALL_SOFTWARES_REQUEST';
export const GET_ALL_SOFTWARES_FAILURE = 'GET_ALL_SOFTWARES_FAILURE';

export const GET_ALL_METADATA_SUCCESS = 'GET_ALL_METADATA_SUCCESS';
export const GET_ALL_METADATA_REQUEST = 'GET_ALL_METADATA_REQUEST';
export const GET_ALL_METADATA_FAILURE = 'GET_ALL_METADATA_FAILURE';

export const GET_ADMIN_USER_SUCCESS = 'GET_ADMIN_USER_SUCCESS';
export const GET_ADMIN_USER_REQUEST = 'GET_ADMIN_USER_REQUEST';
export const GET_ADMIN_USER_FAILURE = 'GET_ADMIN_USER_FAILURE';

export const GET_MANAGERS_LIST_SUCCESS = 'GET_MANAGERS_LIST_SUCCESS';
export const GET_ADMIN_RAM_REQUEST = 'GET_ADMIN_RAM_REQUEST';
export const GET_ADMIN_RAM_SUCCESS = 'GET_ADMIN_RAM_SUCCESS';
export const GET_ADMIN_RAM_FAILURE = 'GET_ADMIN_RAM_FAILURE';

export const UPDATE_ADMIN_RAM_REQUEST = 'UPDATE_ADMIN_RAM_REQUEST';

export const GET_DSO_NAMES_SUCCESS = 'GET_DSO_NAMES_SUCCESS';
export const GET_DSO_NAMES_REQUEST = 'GET_DSO_NAMES_REQUEST';
export const GET_DSO_NAMES_FAILURE = 'GET_DSO_NAMES_FAILURE';

export const GET_REFERRAL_TYPES_SUCCESS = 'GET_REFERRAL_TYPES_SUCCESS';
export const GET_REFERRAL_TYPES_REQUEST = 'GET_REFERRAL_TYPES_REQUEST';
export const GET_REFERRAL_TYPES_FAILURE = 'GET_REFERRAL_TYPES_FAILURE';

export const UPDATE_ADMIN_DSO_MANAGER_REQUEST = 'UPDATE_ADMIN_DSO_MANAGER_REQUEST';

export const UPDATE_ADMIN_DSO_NAME_REQUEST = 'UPDATE_ADMIN_DSO_NAME_REQUEST';

export const GET_CANCELLATION_POLICIES_SUCCESS = 'GET_CANCELLATION_POLICIES_SUCCESS';
export const GET_CANCELLATION_POLICIES_REQUEST = 'GET_CANCELLATION_POLICIES_REQUEST';
export const GET_CANCELLATION_POLICIES_FAILURE = 'GET_CANCELLATION_POLICIES_FAILURE';

export const FETCH_PARTNERSHIP_ORGANIZATIONS_SUCCESS = 'FETCH_PARTNERSHIP_ORGANIZATIONS_SUCCESS';
export const FETCH_PARTNERSHIP_ORGANIZATIONS_REQUEST = 'FETCH_PARTNERSHIP_ORGANIZATIONS_REQUEST';
export const FETCH_PARTNERSHIP_ORGANIZATIONS_FAILURE = 'FETCH_PARTNERSHIP_ORGANIZATIONS_FAILURE';

export const FETCH_JOB_SUCCESS = 'FETCH_JOB_SUCCESS';
export const FETCH_JOB_REQUEST = 'FETCH_JOB_REQUEST';
export const FETCH_JOB_FAILURE = 'FETCH_JOB_FAILURE';

export const FETCH_JOB_VIEWS_SUCCESS = 'FETCH_JOB_VIEWS_SUCCESS';
export const FETCH_JOB_VIEWS_REQUEST = 'FETCH_JOB_VIEWS_REQUEST';
export const FETCH_JOB_VIEWS_FAILURE = 'FETCH_JOB_VIEWS_FAILURE';

export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const CREATE_JOB_REQUEST = 'CREATE_JOB_REQUEST';
export const CREATE_JOB_FAILURE = 'CREATE_JOB_FAILURE';

export const CANCEL_JOB_SUCCESS = 'CANCEL_JOB_SUCCESS';
export const CANCEL_JOB_REQUEST = 'CANCEL_JOB_REQUEST';
export const CANCEL_JOB_FAILURE = 'CANCEL_JOB_FAILURE';

export const ACCEPT_JOB_SUCCESS = 'ACCEPT_JOB_SUCCESS';
export const ACCEPT_JOB_REQUEST = 'ACCEPT_JOB_REQUEST';
export const ACCEPT_JOB_FAILURE = 'ACCEPT_JOB_FAILURE';

export const PAYOUT_JOB_SUCCESS = 'PAYOUT_JOB_SUCCESS';
export const PAYOUT_JOB_REQUEST = 'PAYOUT_JOB_REQUEST';
export const PAYOUT_JOB_FAILURE = 'PAYOUT_JOB_FAILURE';

export const REFUND_JOB_SUCCESS = 'REFUND_JOB_SUCCESS';
export const REFUND_JOB_REQUEST = 'REFUND_JOB_REQUEST';
export const REFUND_JOB_FAILURE = 'REFUND_JOB_FAILURE';

export const EDIT_JOB_SUCCESS = 'EDIT_JOB_SUCCESS';
export const EDIT_JOB_REQUEST = 'EDIT_JOB_REQUEST';
export const EDIT_JOB_FAILURE = 'EDIT_JOB_FAILURE';

export const DISABLE_PAYOUT_SUCCESS = 'DISABLE_PAYOUT_SUCCESS';
export const DISABLE_PAYOUT_REQUEST = 'DISABLE_PAYOUT_REQUEST';
export const DISABLE_PAYOUT_FAILURE = 'DISABLE_PAYOUT_FAILURE';

export const ENABLE_AUTO_REFUND_SUCCESS = 'ENABLE_AUTO_REFUND_SUCCESS';
export const ENABLE_AUTO_REFUND_REQUEST = 'ENABLE_AUTO_REFUND_REQUEST';
export const ENABLE_AUTO_REFUND_FAILURE = 'ENABLE_AUTO_REFUND_FAILURE';

export const STOP_ADDITIONAL_CHARGES_SUCCESS = 'STOP_ADDITIONAL_CHARGES_SUCCESS';
export const STOP_ADDITIONAL_CHARGES_REQUEST = 'STOP_ADDITIONAL_CHARGES_REQUEST';
export const STOP_ADDITIONAL_CHARGES_FAILURE = 'STOP_ADDITIONAL_CHARGES_FAILURE';

export const STOP_INVOICES_SUCCESS = 'STOP_INVOICES_SUCCESS';
export const STOP_INVOICES_REQUEST = 'STOP_INVOICES_REQUEST';
export const STOP_INVOICES_FAILURE = 'STOP_INVOICES_FAILURE';

export const ADJUSTMENT_CHANGE_SUCCESS = 'ADJUSTMENT_CHANGE_SUCCESS';
export const ADJUSTMENT_CHANGE_REQUEST = 'ADJUSTMENT_CHANGE_REQUEST';
export const ADJUSTMENT_CHANGE_FAILURE = 'ADJUSTMENT_CHANGE_FAILURE';

export const FETCH_USER_JOBS_SUCCESS = 'FETCH_USER_JOBS_SUCCESS';
export const FETCH_USER_JOBS_REQUEST = 'FETCH_USER_JOBS_REQUEST';
export const FETCH_USER_JOBS_FAILURE = 'FETCH_USER_JOBS_FAILURE';

export const FETCH_USER_OFFERS_SUCCESS = 'FETCH_USER_OFFERS_SUCCESS';
export const FETCH_USER_OFFERS_REQUEST = 'FETCH_USER_OFFERS_REQUEST';
export const FETCH_USER_OFFERS_FAILURE = 'FETCH_USER_OFFERS_FAILURE';

export const UPDATE_USER_LICENSE_SUCCESS = 'UPDATE_USER_LICENSE_SUCCESS';
export const UPDATE_USER_LICENSE_REQUEST = 'UPDATE_USER_LICENSE_REQUEST';
export const UPDATE_USER_LICENSE_FAILURE = 'UPDATE_USER_LICENSE_FAILURE';

export const UPDATE_NATIONAL_PROVIDER_IDENTIFIER_SUCCESS =
  'UPDATE_NATIONAL_PROVIDER_IDENTIFIER_SUCCESS';
export const UPDATE_NATIONAL_PROVIDER_IDENTIFIER_REQUEST =
  'UPDATE_NATIONAL_PROVIDER_IDENTIFIER_REQUEST';
export const UPDATE_NATIONAL_PROVIDER_IDENTIFIER_FAILURE =
  'UPDATE_NATIONAL_PROVIDER_IDENTIFIER_FAILURE';

export const HIDE_EXISTING_LICENSE_POPUP = 'HIDE_EXISTING_LICENSE_POPUP';

export const ADD_LICENSE_SUCCESS = 'ADD_LICENSE_SUCCESS';
export const ADD_LICENSE_REQUEST = 'ADD_LICENSE_REQUEST';
export const ADD_LICENSE_FAILURE = 'ADD_LICENSE_FAILURE';

export const EDIT_CANCELLATION_PENALTY_SUCCESS = 'EDIT_CANCELLATION_PENALTY_SUCCESS';
export const EDIT_CANCELLATION_PENALTY_REQUEST = 'EDIT_CANCELLATION_PENALTY_REQUEST';
export const EDIT_CANCELLATION_PENALTY_FAILURE = 'EDIT_CANCELLATION_PENALTY_FAILURE';

export const CREATE_STRIPE_ACCOUNT_REQUEST = 'CREATE_STRIPE_ACCOUNT_REQUEST';
export const CREATE_STRIPE_ACCOUNT_SUCCESS = 'CREATE_STRIPE_ACCOUNT_SUCCESS';
export const CREATE_STRIPE_ACCOUNT_FAILURE = 'CREATE_STRIPE_ACCOUNT_FAILURE';

export const DELETE_LICENSE_SUCCESS = 'DELETE_LICENSE_SUCCESS';
export const DELETE_LICENSE_REQUEST = 'DELETE_LICENSE_REQUEST';
export const DELETE_LICENSE_FAILURE = 'DELETE_LICENSE_FAILURE';

export const GET_USER_PROFESSIONS_SUCCESS = 'GET_USER_PROFESSIONS_SUCCESS';
export const GET_USER_PROFESSIONS_REQUEST = 'GET_USER_PROFESSIONS_REQUEST';
export const GET_USER_PROFESSIONS_FAILURE = 'GET_USER_PROFESSIONS_FAILURE';

export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const RELOAD_JOBS_SUCCESS = 'RELOAD_JOBS_SUCCESS';
export const RELOAD_JOBS_REQUEST = 'RELOAD_JOBS_REQUEST';
export const RELOAD_JOBS_FAILURE = 'RELOAD_JOBS_FAILURE';

export const CLEAR_DATE_RANGE_JOBS_SUCCESS = 'CLEAR_DATE_RANGE_JOBS_SUCCESS';

export const DELETE_HYG_FROM_OFFER_SUCCESS = 'DELETE_HYG_FROM_OFFER_SUCCESS';
export const DELETE_HYG_FROM_OFFER_REQUEST = 'DELETE_HYG_FROM_OFFER_REQUEST';
export const DELETE_HYG_FROM_OFFER_FAILURE = 'DELETE_HYG_FROM_OFFER_FAILURE';

export const JOB_ACTION = {
  DECLINE_COUINTER_OFFER_SUCCESS: 'DECLINE_COUINTER_OFFER_SUCCESS',
  DECLINE_COUINTER_OFFER_REQUEST: 'DECLINE_COUINTER_OFFER_REQUEST',
  DECLINE_COUINTER_OFFER_FAILURE: 'DECLINE_COUINTER_OFFER_FAILURE',

  CONFIRM_JOB_SUCCESS: 'CONFIRM_JOB_SUCCESS',
  CONFIRM_JOB_REQUEST: 'CONFIRM_JOB_REQUEST',
  CONFIRM_JOB_FAILURE: 'CONFIRM_JOB_FAILURE',

  SUBMIT_ADJUSTMENT_SUCCESS: 'SUBMIT_ADJUSTMENT_SUCCESS',
  SUBMIT_ADJUSTMENT_REQUEST: 'SUBMIT_ADJUSTMENT_REQUEST',
  SUBMIT_ADJUSTMENT_FAILURE: 'SUBMIT_ADJUSTMENT_FAILURE',

  REMOVE_JOB_UNAVAILABLE_USERS_SUCCESS: 'REMOVE_JOB_UNAVAILABLE_USERS_SUCCESS',
  REMOVE_JOB_UNAVAILABLE_USERS_REQUEST: 'REMOVE_JOB_UNAVAILABLE_USERS_REQUEST',
  REMOVE_JOB_UNAVAILABLE_USERS_FAILURE: 'REMOVE_JOB_UNAVAILABLE_USERS_FAILURE',

  BULK_CANCEL_JOBS_SUCCESS: 'BULK_CANCEL_JOBS_SUCCESS',
  BULK_CANCEL_JOBS_REQUEST: 'BULK_CANCEL_JOBS_REQUEST',
  BULK_CANCEL_JOBS_FAILURE: 'BULK_CANCEL_JOBS_FAILURE',
};
export const FETCH_USERS = 'FETCH_USERS';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';

export const RESUME_UPLOAD_REQUEST = 'RESUME_UPLOAD_REQUEST';
export const RESUME_UPLOAD_SUCCESS = 'RESUME_UPLOAD_SUCCESS';
export const RESUME_UPLOAD_FAILURE = 'RESUME_UPLOAD_FAILURE';

// auth

export const AUTH_TOKEN_EXPIRED = 'AUTH_TOKEN_EXPIRED';

export const AUTH_SIGN_IN_REQUEST = 'AUTH_SIGN_IN_REQUEST';
export const AUTH_SIGN_IN_SUCCESS = 'AUTH_SIGN_IN_SUCCESS';
export const AUTH_SIGN_IN_FAILURE = 'AUTH_SIGN_IN_FAILURE';

export const AUTH_SIGN_IN_GOOGLE_REQUEST = 'AUTH_SIGN_IN_GOOGLE_REQUEST';
export const AUTH_SIGN_IN_GOOGLE_SUCCESS = 'AUTH_SIGN_IN_GOOGLE_SUCCESS';
export const AUTH_SIGN_IN_GOOGLE_FAILURE = 'AUTH_SIGN_IN_GOOGLE_FAILURE';

export const AUTH_REFRESH_REQUEST = 'AUTH_REFRESH_REQUEST';
export const AUTH_REFRESH_SUCCESS = 'AUTH_REFRESH_SUCCESS';
export const AUTH_REFRESH_FAILURE = 'AUTH_REFRESH_FAILURE';

// CMS
export const INDEED_FETCH_USERS_REPORT_REQUEST = 'INDEED_FETCH_USERS_REPORT_REQUEST';
export const INDEED_FETCH_USERS_REPORT_SUCCESS = 'INDEED_FETCH_USERS_REPORT_SUCCESS';
export const INDEED_FETCH_USERS_REPORT_FAILURE = 'INDEED_FETCH_USERS_REPORT_FAILURE';

export const CMS_FETCH_USERS_REPORT_REQUEST = 'CMS_FETCH_USERS_REPORT_REQUEST';
export const CMS_FETCH_USERS_REPORT_SUCCESS = 'CMS_FETCH_USERS_REPORT_SUCCESS';
export const CMS_FETCH_USERS_REPORT_FAILURE = 'CMS_FETCH_USERS_REPORT_FAILURE';

export const CMS_FETCH_USERS_REQUEST = 'CMS_FETCH_USERS_REQUEST';
export const CMS_FETCH_USERS_SUCCESS = 'CMS_FETCH_USERS_SUCCESS';
export const CMS_FETCH_USERS_FAILURE = 'CMS_FETCH_USERS_FAILURE';
export const CMS_CLEAR_USERS_REPORT = 'CMS_CLEAR_USERS_REPORT';

// paymnets

export const PAYMENT_METHODS_ACTIONS = {
  GET_ALL_PAYMENT_REQUEST: 'GET_ALL_PAYMENT_REQUEST',
  GET_ALL_PAYMENT_SUCCESS: 'GET_ALL_PAYMENT_SUCCESS',
  GET_ALL_PAYMENT_FAILURE: 'GET_ALL_PAYMENT_FAILURE',

  HYG_GET_ALL_PAYMENT_REQUEST: 'HYG_GET_ALL_PAYMENT_REQUEST',
  HYG_GET_ALL_PAYMENT_SUCCESS: 'HYG_GET_ALL_PAYMENT_SUCCESS',
  HYG_GET_ALL_PAYMENT_FAILURE: 'HYG_GET_ALL_PAYMENT_FAILURE',

  SELECT_DNT_PAYMENT_REQUEST: 'SELECT_DNT_PAYMENT_REQUEST',
  SELECT_DNT_PAYMENT_SUCCESS: 'SELECT_DNT_PAYMENT_SUCCESS',
  SELECT_DNT_PAYMENT_FAILURE: 'SELECT_DNT_PAYMENT_FAILURE',

  SELECT_HYG_PAYMENT_REQUEST: 'SELECT_HYG_PAYMENT_REQUEST',
  SELECT_HYG_PAYMENT_SUCCESS: 'SELECT_HYG_PAYMENT_SUCCESS',
  SELECT_HYG_PAYMENT_FAILURE: 'SELECT_HYG_PAYMENT_FAILURE',

  SELECT_HYG_MARKETING_PAYMENT_REQUEST: 'SELECT_HYG_MARKETING_PAYMENT_REQUEST',
  SELECT_HYG_MARKETING_PAYMENT_SUCCESS: 'SELECT_HYG_MARKETING_PAYMENT_SUCCESS',
  SELECT_HYG_MARKETING_PAYMENT_FAILURE: 'SELECT_HYG_MARKETING_PAYMENT_FAILURE',

  MARK_INVOICE_AS_PAID_REQUEST: 'MARK_INVOICE_AS_PAID_REQUEST',
  MARK_INVOICE_AS_PAID_SUCCESS: 'MARK_INVOICE_AS_PAID_SUCCESS',
  MARK_INVOICE_AS_PAID_FAILURE: 'MARK_INVOICE_AS_PAID_FAILURE',

  CREATE_INSTANT_PAYOUT_REQUEST: 'CREATE_INSTANT_PAYOUT_REQUEST',
  CREATE_INSTANT_PAYOUT_SUCCESS: 'CREATE_INSTANT_PAYOUT_SUCCESS',
  CREATE_INSTANT_PAYOUT_FAILURE: 'CREATE_INSTANT_PAYOUT_FAILURE',

  WAIVE_INSTANT_PAYOUT_REQUEST: 'WAIVE_INSTANT_PAYOUT_REQUEST',
  WAIVE_INSTANT_PAYOUT_SUCCESS: 'WAIVE_INSTANT_PAYOUT_SUCCESS',
  WAIVE_INSTANT_PAYOUT_FAILURE: 'WAIVE_INSTANT_PAYOUT_FAILURE',

  UPDATE_TRANSACTION_STATUS_REQUEST: 'UPDATE_TRANSACTION_STATUS_REQUEST',
  UPDATE_TRANSACTION_STATUS_SUCCESS: 'UPDATE_TRANSACTION_STATUS_SUCCESS',
  UPDATE_TRANSACTION_STATUS_FAILURE: 'UPDATE_TRANSACTION_STATUS_FAILURE',

  DELETE_INSTANT_TRANSACTION_REQUEST: 'DELETE_INSTANT_TRANSACTION_REQUEST',
  DELETE_INSTANT_TRANSACTION_SUCCESS: 'DELETE_INSTANT_TRANSACTION_SUCCESS',
  DELETE_INSTANT_TRANSACTION_FAILURE: 'DELETE_INSTANT_TRANSACTION_FAILURE',

  DELETE_INVOICE_REQUEST: 'DELETE_INVOICE_REQUEST',
  DELETE_INVOICE_SUCCESS: 'DELETE_INVOICE_SUCCESS',
  DELETE_INVOICE_FAILURE: 'DELETE_INVOICE_FAILURE',

  SEND_JOB_COUNTER_COVER_REQUEST: 'SEND_JOB_COUNTER_COVER_REQUEST',
  SEND_JOB_COUNTER_COVER_SUCCESS: 'SEND_JOB_COUNTER_COVER_SUCCESS',
  SEND_JOB_COUNTER_COVER_FAILURE: 'SEND_JOB_COUNTER_COVER_FAILURE',

  REMOVE_JOB_COUNTER_COVER_REQUEST: 'REMOVE_JOB_COUNTER_COVER_REQUEST',
  REMOVE_JOB_COUNTER_COVER_SUCCESS: 'REMOVE_JOB_COUNTER_COVER_SUCCESS',
  REMOVE_JOB_COUNTER_COVER_FAILURE: 'REMOVE_JOB_COUNTER_COVER_FAILURE',

  CREATE_MARK_JOB_PAID_OUT_REQUEST: 'CREATE_MARK_JOB_PAID_OUT_REQUEST',
  CREATE_MARK_JOB_PAID_OUT_SUCCESS: 'CREATE_MARK_JOB_PAID_OUT_SUCCESS',
  CREATE_MARK_JOB_PAID_OUT_FAILURE: 'CREATE_MARK_JOB_PAID_OUT_FAILURE',

  SEND_PROFESSIONAL_PAYOUT_REQUEST: 'SEND_PROFESSIONAL_PAYOUT_REQUEST',
  SEND_PROFESSIONAL_PAYOUT_SUCCESS: 'SEND_PROFESSIONAL_PAYOUT_SUCCESS',
  SEND_PROFESSIONAL_PAYOUT_FAILURE: 'SEND_PROFESSIONAL_PAYOUT_FAILURE',
};

// sms

export const GET_ALL_USER_SMS_SUCCESS = 'GET_ALL_USER_SMS_SUCCESS';
export const GET_ALL_USER_SMS_REQUEST = 'GET_ALL_USER_SMS_REQUEST';
export const GET_ALL_USER_SMS_FAILURE = 'GET_ALL_USER_SMS_FAILURE';

export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
export const SEND_SMS_REQUEST = 'SEND_SMS_REQUEST';
export const SEND_SMS_FAILURE = 'SEND_SMS_FAILURE';

export const SEND_SMS_PHONES_SUCCESS = 'SEND_SMS_PHONES_SUCCESS';
export const SEND_SMS_PHONES_REQUEST = 'SEND_SMS_PHONES_REQUEST';
export const SEND_SMS_PHONES_FAILURE = 'SEND_SMS_PHONES_FAILURE';

// licenses

export const GET_ALL_UNVERIFIED_LICENSES_SUCCESS = 'GET_ALL_UNVERIFIED_LICENSES_SUCCESS';
export const GET_ALL_UNVERIFIED_LICENSES_REQUEST = 'GET_ALL_UNVERIFIED_LICENSES_REQUEST';
export const GET_ALL_UNVERIFIED_LICENSES_FAILURE = 'GET_ALL_UNVERIFIED_LICENSES_FAILURE';

export const CREDENTIAL_ACTION = {
  GET_ALL_STATE_CREDENTIALS_SUCCESS: 'GET_ALL_STATE_CREDENTIALS_SUCCESS',
  GET_ALL_STATE_CREDENTIALS_REQUEST: 'GET_ALL_STATE_CREDENTIALS_REQUEST',
  GET_ALL_STATE_CREDENTIALS_FAILURE: 'GET_ALL_STATE_CREDENTIALS_FAILURE',

  UPLOAD_STATE_CREDENTIALS_REQUEST: 'UPLOAD_STATE_CREDENTIALS_REQUEST',
  UPLOAD_STATE_CREDENTIALS_FAILURE: 'UPLOAD_STATE_CREDENTIALS_FAILURE',
  UPLOAD_STATE_TYPE_CREDENTIALS_REQUEST: 'UPLOAD_STATE_TYPE_CREDENTIALS_REQUEST',
  UPLOAD_STATE_TYPE_CREDENTIALS_FAILURE: 'UPLOAD_STATE_TYPE_CREDENTIALS_FAILURE',

  POST_ENTRY_STATE_TYPE_CREDENTIALS_REQUEST: 'POST_ENTRY_STATE_TYPE_CREDENTIALS_REQUEST',
  POST_ENTRY_STATE_TYPE_CREDENTIALS_FAILURE: 'POST_ENTRY_STATE_TYPE_CREDENTIALS_FAILURE',

  PATCH_ENTRY_STATE_TYPE_CREDENTIALS_REQUEST: 'PATCH_ENTRY_STATE_TYPE_CREDENTIALS_REQUEST',
  PATCH_ENTRY_STATE_TYPE_CREDENTIALS_FAILURE: 'PATCH_ENTRY_STATE_TYPE_CREDENTIALS_FAILURE',

  DELETE_CREDENTIALS_UPLOAD_REQUEST: 'DELETE_CREDENTIALS_UPLOAD_REQUEST',
  DELETE_CREDENTIALS_UPLOAD_FAILURE: 'DELETE_CREDENTIALS_UPLOAD_FAILURE',
};

export const EDUCATION_ACTION = {
  GET_USER_EDUCATION_SUCCESS: 'GET_USER_EDUCATION_SUCCESS',
  GET_USER_EDUCATION_REQUEST: 'GET_USER_EDUCATION_REQUEST',
  GET_USER_EDUCATION_FAILURE: 'GET_USER_EDUCATION_FAILURE',

  FETCH_REQUESTED_EDUCATIONS_SUCCESS: 'FETCH_REQUESTED_EDUCATIONS_SUCCESS',
  FETCH_REQUESTED_EDUCATIONS_REQUEST: 'FETCH_REQUESTED_EDUCATIONS_REQUEST',
  FETCH_REQUESTED_EDUCATIONS_FAILURE: 'FETCH_REQUESTED_EDUCATIONS_FAILURE',

  UPDATE_USER_EDUCATION_SUCCESS: 'UPDATE_USER_EDUCATION_SUCCESS',
  UPDATE_USER_EDUCATION_REQUEST: 'UPDATE_USER_EDUCATION_REQUEST',
  UPDATE_USER_EDUCATION_FAILURE: 'UPDATE_USER_EDUCATION_FAILURE',
};

// map
export const MAP_TYPES = {
  FETCH_USER_LIST_MAP_SUCCESS: 'FETCH_USER_LIST_MAP_SUCCESS',
  FETCH_USER_LIST_MAP_REQUEST: 'FETCH_USER_LIST_MAP_REQUEST',
  FETCH_USER_LIST_MAP_FAILURE: 'FETCH_USER_LIST_MAP_FAILURE',

  FETCH_USERS_AROUND_SUCCESS: 'FETCH_USERS_AROUND_SUCCESS',
  FETCH_USERS_AROUND_REQUEST: 'FETCH_USERS_AROUND_REQUEST',
  FETCH_USERS_AROUND_FAILURE: 'FETCH_USERS_AROUND_FAILURE',
};

// messages

export const CHAT_TYPES = {
  GET_JOBS_WITH_CONVERSATIONS_SUCCESS: 'GET_JOBS_WITH_CONVERSATIONS_SUCCESS',
  GET_JOBS_WITH_CONVERSATIONS_REQUEST: 'GET_JOBS_WITH_CONVERSATIONS_REQUEST',
  GET_JOBS_WITH_CONVERSATIONS_FAILURE: 'GET_JOBS_WITH_CONVERSATIONS_FAILURE',

  GET_ALL_MESSAGES_SUCCESS: 'GET_ALL_MESSAGES_SUCCESS',
  GET_ALL_MESSAGES_REQUEST: 'GET_ALL_MESSAGES_REQUEST',
  GET_ALL_MESSAGES_FAILURE: 'GET_ALL_MESSAGES_FAILURE',

  POST_MESSAGE_SUCCESS: 'POST_MESSAGE_SUCCESS',
  POST_MESSAGE_REQUEST: 'POST_MESSAGE_REQUEST',
  POST_MESSAGE_FAILURE: 'POST_MESSAGE_FAILURE',

  CHANGE_STATUS_SUCCESS: 'CHANGE_STATUS_SUCCESS',
  CHANGE_STATUS_REQUEST: 'CHANGE_STATUS_REQUEST',
  CHANGE_STATUS_FAILURE: 'CHANGE_STATUS_FAILURE',

  UPDATE_READ_TIME_SUCCESS: 'UPDATE_READ_TIME_SUCCESS',

  NEW_MESSAGE_SUCCESS: 'NEW_MESSAGE_SUCCESS',
  NEW_MESSAGE_REQUEST: 'NEW_MESSAGE_REQUEST',
  NEW_MESSAGE_FAILURE: 'NEW_MESSAGE_FAILURE',
};

export const REPORT_ACTIONS = {
  FETCH_USERS_REPORT_REQUEST: 'FETCH_USERS_REPORT_REQUEST',
  FETCH_USERS_REPORT_FAILURE: 'FETCH_USERS_REPORT_FAILURE',
  FETCH_USERS_REPORT_SUCCESS: 'FETCH_USERS_REPORT_SUCCESS',

  FETCH_USER_TAX_REPORT_REQUEST: 'FETCH_USER_TAX_REPORT_REQUEST',
  FETCH_USER_TAX_REPORT_FAILURE: 'FETCH_USER_TAX_REPORT_FAILURE',
  FETCH_USER_TAX_REPORT_SUCCESS: 'FETCH_USER_TAX_REPORT_SUCCESS',

  FETCH_JOBS_REPORT_REQUEST: 'FETCH_JOBS_REPORT_REQUEST',
  FETCH_JOBS_REPORT_FAILURE: 'FETCH_JOBS_REPORT_FAILURE',
  FETCH_JOBS_REPORT_SUCCESS: 'FETCH_JOBS_REPORT_SUCCESS',

  CLEAR_JOBS_REPORT_REQUEST: 'CLEAR_JOBS_REPORT_REQUEST',
  CLEAR_JOBS_REPORT_FAILURE: 'CLEAR_JOBS_REPORT_FAILURE',
  CLEAR_JOBS_REPORT_SUCCESS: 'CLEAR_JOBS_REPORT_SUCCESS',
};
// stats

export const GET_ALL_STATS_SUCCESS = 'GET_ALL_STATS_SUCCESS';
export const GET_ALL_STATS_REQUEST = 'GET_ALL_STATS_REQUEST';
export const GET_ALL_STATS_FAILURE = 'GET_ALL_STATS_FAILURE';

export const GET_TABLEAU_REQUEST = 'GET_TABLEAU_REQUEST';
export const GET_TABLEAU_SUCCESS = 'GET_TABLEAU_SUCCESS';
export const GET_TABLEAU_FAILURE = 'GET_TABLEAU_FAILURE';

// pp
export const FETCH_PP_JOBS_SUCCESS = 'FETCH_PP_JOBS_SUCCESS';
export const FETCH_PP_JOBS_REQUEST = 'FETCH_PP_JOBS_REQUEST';
export const FETCH_PP_JOBS_FAILURE = 'FETCH_PP_JOBS_FAILURE';

export const POST_PP_JOB_SUCCESS = 'POST_PP_JOB_SUCCESS';
export const POST_PP_JOB_REQUEST = 'POST_PP_JOB_REQUEST';
export const POST_PP_JOB_FAILURE = 'POST_PP_JOB_FAILURE';

export const FETCH_PP_JOB_SUCCESS = 'FETCH_PP_JOB_SUCCESS';
export const FETCH_PP_JOB_REQUEST = 'FETCH_PP_JOB_REQUEST';
export const FETCH_PP_JOB_FAILURE = 'FETCH_PP_JOB_FAILURE';

export const EDIT_PP_JOB_SUCCESS = 'EDIT_PP_JOB_SUCCESS';
export const EDIT_PP_JOB_REQUEST = 'EDIT_PP_JOB_REQUEST';
export const EDIT_PP_JOB_FAILURE = 'EDIT_PP_JOB_FAILURE';

export const ASSIGN_OFFICE_PP_JOB_SUCCESS = 'ASSIGN_OFFICE_PP_JOB_SUCCESS';
export const ASSIGN_OFFICE_PP_JOB_REQUEST = 'ASSIGN_OFFICE_PP_JOB_REQUEST';
export const ASSIGN_OFFICE_PP_JOB_FAILURE = 'ASSIGN_OFFICE_PP_JOB_FAILURE';
// multiday

export const MULTIDAY_ACTIONS = {
  FETCH_MULTIDAY_JOBS_SUCCESS: 'FETCH_MULTIDAY_JOBS_SUCCESS',
  FETCH_MULTIDAY_JOBS_REQUEST: 'FETCH_MULTIDAY_JOBS_REQUEST',
  FETCH_MULTIDAY_JOBS_FAILURE: 'FETCH_MULTIDAY_JOBS_FAILURE',

  FETCH_MDB_BY_ID_SUCCESS: 'FETCH_MDB_BY_ID_SUCCESS',
  FETCH_MDB_BY_ID_REQUEST: 'FETCH_MDB_BY_ID_REQUEST',
  FETCH_MDB_BY_ID_FAILURE: 'FETCH_MDB_BY_ID_FAILURE',

  FETCH_MDB_JOBS_BY_ID_SUCCESS: 'FETCH_MDB_JOBS_BY_ID_SUCCESS',
  FETCH_MDB_JOBS_BY_ID_REQUEST: 'FETCH_MDB_JOBS_BY_ID_REQUEST',
  FETCH_MDB_JOBS_BY_ID_FAILURE: 'FETCH_MDB_JOBS_BY_ID_FAILURE',

  PREPARE_DATA_MDB_POST_SUCCESS: 'PREPARE_DATA_MDB_POST_SUCCESS',
  CANCEL_POSTING_SUCCESS: 'CANCEL_POSTING_SUCCESS',

  POST_MULTIDAY_SUCCESS: 'POST_MULTIDAY_SUCCESS',
  POST_MULTIDAY_REQUEST: 'POST_MULTIDAY_REQUEST',
  POST_MULTIDAY_FAILURE: 'POST_MULTIDAY_FAILURE',
};

// referral

export const REFERRAL_ACTIONS = {
  GET_REFERRAL_SUCCESS: 'GET_REFERRAL_SUCCESS',
  GET_REFERRAL_REQUEST: 'GET_REFERRAL_REQUEST',
  GET_REFERRAL_FAILURE: 'GET_REFERRAL_FAILURE',
  PAYOUT_REFERRAL_REQUEST: 'PAYOUT_REFERRAL_REQUEST',
  PAYOUT_REFERRAL_SUCCESS: 'PAYOUT_REFERRAL_SUCCESS',
  PAYOUT_REFERRAL_FAILURE: 'PAYOUT_REFERRAL_FAILURE',
};

export const PP_JOB_ACTIONS = {
  HIDE_SELECTED_APPLICATION: 'HIDE_SELECTED_APPLICATION',

  FETCH_USER_PP_JOBS_SUCCESS: 'FETCH_USER_PP_JOBS_SUCCESS',
  FETCH_USER_PP_JOBS_REQUEST: 'FETCH_USER_PP_JOBS_REQUEST',
  FETCH_USER_PP_JOBS_FAILURE: 'FETCH_USER_PP_JOBS_FAILURE',

  APPLY_FOR_JOB_REQUEST: 'APPLY_FOR_JOB_REQUEST',
  APPLY_FOR_JOB_SUCCESS: 'APPLY_FOR_JOB_SUCCESS',
  APPLY_FOR_JOB_FAILURE: 'APPLY_FOR_JOB_FAILURE',

  CREATE_ACTION_PP_JOBS_SUCCESS: 'CREATE_ACTION_PP_JOBS_SUCCESS',
  CREATE_ACTION_PP_JOBS_REQUEST: 'CREATE_ACTION_PP_JOBS_REQUEST',
  CREATE_ACTION_PP_JOBS_FAILURE: 'CREATE_ACTION_PP_JOBS_FAILURE',

  GET_PP_APPLICATIONS_JOBS_SUCCESS: 'GET_PP_APPLICATIONS_JOBS_SUCCESS',
  GET_PP_APPLICATIONS_JOBS_REQUEST: 'GET_PP_APPLICATIONS_JOBS_REQUEST',
  GET_PP_APPLICATIONS_JOBS_FAILURE: 'GET_PP_APPLICATIONS_JOBS_FAILURE',

  UPLOAD_APPLICATION_RESUME_JOBS_SUCCESS: 'UPLOAD_APPLICATION_RESUME_JOBS_SUCCESS',
  UPLOAD_APPLICATION_RESUME_JOBS_REQUEST: 'UPLOAD_APPLICATION_RESUME_JOBS_REQUEST',
  UPLOAD_APPLICATION_RESUME_JOBS_FAILURE: 'UPLOAD_APPLICATION_RESUME_JOBS_FAILURE',

  GET_S3_URL_RESUME_SUCCESS: 'GET_S3_URL_RESUME_SUCCESS',
  GET_S3_URL_RESUME_REQUEST: 'GET_S3_URL_RESUME_REQUEST',
  GET_S3_URL_RESUME_FAILURE: 'GET_S3_URL_RESUME_FAILURE',

  GET_PP_APPLICATION_SUCCESS: 'GET_PP_APPLICATION_SUCCESS',
  GET_PP_APPLICATION_REQUEST: 'GET_PP_APPLICATION_REQUEST',
  GET_PP_APPLICATION_FAILURE: 'GET_PP_APPLICATION_FAILURE',

  SET_INTERVIEW_TIME_SUCCESS: 'SET_INTERVIEW_TIME_SUCCESS',
  SET_INTERVIEW_TIME_REQUEST: 'SET_INTERVIEW_TIME_REQUEST',
  SET_INTERVIEW_TIME_FAILURE: 'SET_INTERVIEW_TIME_FAILURE',

  FETCH_APPLICATION_HISTORY_SUCCESS: 'FETCH_APPLICATION_HISTORY_SUCCESS',
  FETCH_APPLICATION_HISTORY_REQUEST: 'FETCH_APPLICATION_HISTORY_REQUEST',
  FETCH_APPLICATION_HISTORY_FAILURE: 'FETCH_APPLICATION_HISTORY_FAILURE',

  UPDATE_PP_APPLICATION_SUCCESS: 'UPDATE_PP_APPLICATION_SUCCESS',
  UPDATE_PP_APPLICATION_REQUEST: 'UPDATE_PP_APPLICATION_REQUEST',
  UPDATE_PP_APPLICATION_FAILURE: 'UPDATE_PP_APPLICATION_FAILURE',

  SET_RESUME_DETAILS_SUCCESS: 'SET_RESUME_DETAILS_SUCCESS',
  SET_RESUME_DETAILS_REQUEST: 'SET_RESUME_DETAILS_REQUEST',
  SET_RESUME_DETAILS_FAILURE: 'SET_RESUME_DETAILS_FAILURE',
};

export const PP_RECRUITER_ACTIONS = {
  FETCH_PP_RECRUITER_REQUEST: 'FETCH_PP_RECRUITER_REQUEST',
  FETCH_PP_RECRUITER_SUCCESS: 'FETCH_PP_RECRUITER_SUCCESS',
  FETCH_PP_RECRUITER_FAILURE: 'FETCH_PP_RECRUITER_FAILURE',
};

export const USER_ACTIONS = {
  GET_OFFICE_RECEIPTS_SUCCESS: 'GET_OFFICE_RECEIPTS_SUCCESS',
  GET_OFFICE_RECEIPTS_REQUEST: 'GET_OFFICE_RECEIPTS_REQUEST',
  GET_OFFICE_RECEIPTS_FAILURE: 'GET_OFFICE_RECEIPTS_FAILURE',

  GET_OFFICES_BLOCKED_SUCCESS: 'GET_OFFICES_BLOCKED_SUCCESS',
  GET_OFFICES_BLOCKED_REQUEST: 'GET_OFFICES_BLOCKED_REQUEST',
  GET_OFFICES_BLOCKED_FAILURE: 'GET_OFFICES_BLOCKED_FAILURE',

  FETCH_USER_REVIEWS_SUCCESS: 'FETCH_USER_REVIEWS_SUCCESS',
  FETCH_USER_REVIEWS_REQUEST: 'FETCH_USER_REVIEWS_REQUEST',
  FETCH_USER_REVIEWS_FAILURE: 'FETCH_USER_REVIEWS_FAILURE',

  GET_OFFICE_INVOICES_SUCCESS: 'GET_USER_INVOICES_SUCCESS',
  GET_OFFICE_INVOICES_REQUEST: 'GET_USER_INVOICES_REQUEST',
  GET_OFFIC_INVOICES_FAILURE: 'GET_USER_INVOICES_FAILURE',

  UPDATE_USER_STATUS: 'UPDATE_USER_STATUS',

  UPDATE_MEDMAL_STATUS_REQUEST: 'UPDATE_MEDMAL_STATUS_REQUEST',
  UPDATE_MEDMAL_STATUS_SUCCESS: 'UPDATE_MEDMAL_STATUS_SUCCESS',
  UPDATE_MEDMAL_STATUS_FAILURE: 'UPDATE_MEDMAL_STATUS_FAILURE',

  FETCH_PROFESSIONAL_STATS_SUCCESS: 'FETCH_PROFESSIONAL_STATS_SUCCESS',
  FETCH_PROFESSIONAL_STATS_REQUEST: 'FETCH_PROFESSIONAL_STATS_REQUEST',
  FETCH_PROFESSIONAL_STATS_FAILURE: 'FETCH_PROFESSIONAL_STATS_FAILURE',

  FETCH_PROFESSIONAL_STATS_BY_DAY_SUCCESS: 'FETCH_PROFESSIONAL_STATS_BY_DAY_SUCCESS',
  FETCH_PROFESSIONAL_STATS_BY_DAY_REQUEST: 'FETCH_PROFESSIONAL_STATS_BY_DAY_REQUEST',
  FETCH_PROFESSIONAL_STATS_BY_DAY_FAILURE: 'FETCH_PROFESSIONAL_STATS_BY_DAY_FAILURE',

  RESET_UNFINISHED_USER_LIST_SEARCH: 'RESET_UNFINISHED_USER_LIST_SEARCH',
  RESET_DSO_LIST_SEARCH: 'RESET_DSO_LIST_SEARCH',
  RESET_DENTIST_LIST_SEARCH: 'RESET_DENTIST_LIST_SEARCH',
  RESET_HYGIENIST_LIST_SEARCH: 'RESET_HYGIENIST_LIST_SEARCH',
};

export const METRICS_ACTION = {
  FETCH_HYG_BADGES_REQUEST: 'FETCH_HYG_BADGES_REQUEST',
  FETCH_HYG_BADGES_SUCCESS: 'FETCH_HYG_BADGES_SUCCESS',
  FETCH_HYG_BADGES_FAILURE: 'FETCH_HYG_BADGES_FAILURE',

  FETCH_HYG_LOYALTY_LEVEL_REQUEST: 'FETCH_HYG_LOYALTY_LEVEL_REQUEST',
  FETCH_HYG_LOYALTY_LEVEL_SUCCESS: 'FETCH_HYG_LOYALTY_LEVEL_SUCCESS',
  FETCH_HYG_LOYALTY_LEVEL_FAILURE: 'FETCH_HYG_LOYALTY_LEVEL_FAILURE',
};

export const FETCH_JOB_VIEWS_FROM_JOB_VIEW_SERVICE_REQUEST =
  'FETCH_JOB_VIEWS_FROM_JOB_VIEW_SERVICE_REQUEST';
export const FETCH_JOB_VIEWS_FROM_JOB_VIEW_SERVICE_SUCCESS =
  'FETCH_JOB_VIEWS_FROM_JOB_VIEW_SERVICE_SUCCESS';
export const FETCH_JOB_VIEWS_FROM_JOB_VIEW_SERVICE_FAILURE =
  'FETCH_JOB_VIEWS_FROM_JOB_VIEW_SERVICE_FAILURE';
