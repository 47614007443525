import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserOffers } from '../../actions/userActions';
import { createLoadingSelector } from '../../apis/selectors';
import { Table } from './Table/Table';
import { customColumnOffers, jobListConditionalRowStyles } from './jobs-column';

const ROWS_PER_PAGE = 15;

const actions = ['FETCH_USER_OFFERS'];
const loadingSelector = createLoadingSelector(actions);

export const UserOfferList = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => loadingSelector(state));
  const jobs = useSelector((state) => state.users.userOffers);
  const totalFiltered = useSelector((state) => state.users.totalOffersCount);
  const searchParams = useSelector((state) => state.users.searchParamsOffers);
  const user = useSelector((state) => state.users.currentUser);
  const [filter, setFilter] = useState({
    licenseTypes: user?.licenseTypes || [],
    page: searchParams?.page || 1,
    sortField: searchParams?.sortField || 'created_at',
    order: searchParams?.order || 'desc',
  });
  const totalPages = Math.ceil(totalFiltered / ROWS_PER_PAGE);

  const handleOnSort = (selectedColumn, sortDirection) => {
    const sortObj = {
      page: filter.page,
      sortField: selectedColumn.sortField,
      sortOrder: sortDirection,
    };

    setFilter((prevState) => ({
      ...prevState,
      ...sortObj,
    }));
  };

  const handleTableChange = (page) => {
    setFilter((prevState) => ({ ...prevState, page }));
  };

  useEffect(() => {
    dispatch(
      getUserOffers({
        ...filter,
      }),
    );
  }, [filter, dispatch]);

  const getJobUrl = (job) => `/job/${job.id}`;

  const getColumn = () =>
    customColumnOffers({
      getJobUrl,
    });

  return (
    <div
      className="card"
      style={{
        display: 'flex',
        height: '900px',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
        width: '80%',
        padding: 20,
        paddingTop: 32,
        marginTop: 24,
      }}
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          marginBottom: -100,
        }}
      >
        <Table
          keyField="userOfferList.id"
          columns={getColumn()}
          data={jobs}
          noDataComponent="There are no jobs"
          isLoading={isLoading}
          totalPages={totalPages}
          fixedHeader
          onSort={handleOnSort}
          paginationPerPage={ROWS_PER_PAGE}
          paginationTotalRows={totalFiltered}
          onPageChange={handleTableChange}
          forceLoading
          conditionalRowStyles={jobListConditionalRowStyles}
        />
      </div>
    </div>
  );
};
