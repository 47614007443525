import tempMee from '../apis/tempMee';
import {
  ACCEPT_JOB_FAILURE,
  ACCEPT_JOB_REQUEST,
  ACCEPT_JOB_SUCCESS,
  ADD_SHIFT_NOTE_FAILURE,
  ADD_SHIFT_NOTE_REQUEST,
  ADD_SHIFT_NOTE_SUCCESS,
  ADJUSTMENT_CHANGE_FAILURE,
  ADJUSTMENT_CHANGE_REQUEST,
  ADJUSTMENT_CHANGE_SUCCESS,
  CANCEL_JOB_FAILURE,
  CANCEL_JOB_REQUEST,
  CANCEL_JOB_SUCCESS,
  CLEAR_DATE_RANGE_JOBS_SUCCESS,
  CREATE_JOB_FAILURE,
  CREATE_JOB_REQUEST,
  CREATE_JOB_SUCCESS,
  DELETE_HYG_FROM_OFFER_FAILURE,
  DELETE_HYG_FROM_OFFER_REQUEST,
  DELETE_HYG_FROM_OFFER_SUCCESS,
  DISABLE_PAYOUT_FAILURE,
  DISABLE_PAYOUT_REQUEST,
  DISABLE_PAYOUT_SUCCESS,
  EDIT_JOB_FAILURE,
  EDIT_JOB_REQUEST,
  EDIT_JOB_SUCCESS,
  ENABLE_AUTO_REFUND_FAILURE,
  ENABLE_AUTO_REFUND_REQUEST,
  ENABLE_AUTO_REFUND_SUCCESS,
  FETCH_JOBS_FAILURE,
  FETCH_JOBS_REQUEST,
  FETCH_JOBS_SUCCESS,
  FETCH_JOB_FAILURE,
  FETCH_JOB_REQUEST,
  FETCH_JOB_SUCCESS,
  FETCH_OPEN_JOBS_FAILURE,
  FETCH_OPEN_JOBS_REQUEST,
  FETCH_OPEN_JOBS_SUCCESS,
  FETCH_SHIFT_NOTES_FAILURE,
  FETCH_SHIFT_NOTES_REQUEST,
  FETCH_SHIFT_NOTES_SUCCESS,
  GET_ALL_PROFESSIONS_FAILURE,
  GET_ALL_PROFESSIONS_REQUEST,
  GET_ALL_PROFESSIONS_SUCCESS,
  GET_ALL_SOFTWARES_FAILURE,
  GET_ALL_SOFTWARES_REQUEST,
  GET_ALL_SOFTWARES_SUCCESS,
  GET_ALL_SPECIALIZATIONS_FAILURE,
  GET_ALL_SPECIALIZATIONS_REQUEST,
  GET_ALL_SPECIALIZATIONS_SUCCESS,
  JOB_ACTION,
  PAYOUT_JOB_FAILURE,
  PAYOUT_JOB_REQUEST,
  PAYOUT_JOB_SUCCESS,
  REFUND_JOB_FAILURE,
  REFUND_JOB_REQUEST,
  REFUND_JOB_SUCCESS,
  RELOAD_JOBS_FAILURE,
  RELOAD_JOBS_REQUEST,
  RELOAD_JOBS_SUCCESS,
  REPORT_ACTIONS,
  SEND_PUSH_FOR_USERS_IN_RADIUS_FAILURE,
  SEND_PUSH_FOR_USERS_IN_RADIUS_REQUEST,
  SEND_PUSH_FOR_USERS_IN_RADIUS_SUCCESS,
  SHOW_MESSAGE,
  STOP_ADDITIONAL_CHARGES_FAILURE,
  STOP_ADDITIONAL_CHARGES_REQUEST,
  STOP_ADDITIONAL_CHARGES_SUCCESS,
  STOP_INVOICES_FAILURE,
  STOP_INVOICES_REQUEST,
  STOP_INVOICES_SUCCESS,
  FETCH_JOB_VIEWS_REQUEST,
  FETCH_JOB_VIEWS_SUCCESS,
  FETCH_JOB_VIEWS_FAILURE,
  FETCH_JOB_VIEWS_FROM_JOB_VIEW_SERVICE_REQUEST,
  FETCH_JOB_VIEWS_FROM_JOB_VIEW_SERVICE_SUCCESS,
  FETCH_JOB_VIEWS_FROM_JOB_VIEW_SERVICE_FAILURE,
} from './actionTypes';

import history from '../history';
import { getAuthHeaders } from './authActions';
import { removeFalsyValues } from '../utils';

export const requestPaymentService = async (jobId) => {
  const headers = await getAuthHeaders();

  return tempMee.get(`payment/admin/order/${jobId}`, {
    headers,
  });
};

export const fetchJobs = (state) => async (dispatch, getState) => {
  const newSearchParams = {
    page: state.page,
    limit: state.sizePerPage,
    searchText: state.searchText || '',
    sortField: state.sortField,
    sortOrder: state.sortOrder,
    jobStatus: state.jobStatus,
    state: state.state,
    startDate: state.startDate,
    endDate: state.endDate,
    profession: state.profession,
    specialtyRdh: state.specialtyRdh,
    specialtyDa: state.specialtyDa,
    workerClassification:
      state.workerClassification !== 'all' ? state.workerClassification : undefined,
    ram: state.ram,
    dsoName: state.dsoName,
    dsoManager: state.dsoManager,
    dsoRamIds: state.dsoRamIds,
  };

  const { searchParams } = getState().jobs;

  if (JSON.stringify(searchParams) === JSON.stringify(newSearchParams)) {
    return;
  }

  dispatch({ type: FETCH_JOBS_REQUEST, payload: newSearchParams });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get('admin/jobs', {
      params: removeFalsyValues(newSearchParams),
      headers,
    });
    dispatch({ type: FETCH_JOBS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: FETCH_JOBS_FAILURE, payload: err });
  }
};

export const reloadJobs = () => async (dispatch, getState) => {
  const { searchParams } = getState().jobs;
  dispatch({ type: RELOAD_JOBS_REQUEST, payload: searchParams });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get('admin/jobs', {
      params: searchParams,
      headers,
    });
    dispatch({ type: RELOAD_JOBS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: RELOAD_JOBS_FAILURE, payload: err });
  }
};

export const clearDataRange = () => ({ type: CLEAR_DATE_RANGE_JOBS_SUCCESS });

export const sendPushForUsersInRadius = (jobId, body) => async (dispatch) => {
  dispatch({ type: SEND_PUSH_FOR_USERS_IN_RADIUS_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(
      `admin/jobs/${jobId}/message/all`,
      {
        min: 0,
        max: parseInt(body.radius),
        message: body.text,
      },
      { headers },
    );
    dispatch({
      type: SEND_PUSH_FOR_USERS_IN_RADIUS_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: SEND_PUSH_FOR_USERS_IN_RADIUS_FAILURE, payload: err });
  }
};

export const deleteHYGFromOffer = (jobId, hygId) => async (dispatch) => {
  dispatch({ type: DELETE_HYG_FROM_OFFER_REQUEST });
  try {
    const headers = await getAuthHeaders();
    await tempMee.delete(`admin/jobs/${jobId}/users/${hygId}`, { headers });
    dispatch({ type: DELETE_HYG_FROM_OFFER_SUCCESS, payload: hygId });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The hygienist was deleted successfully from offer.',
      },
    });
  } catch (err) {
    dispatch({ type: DELETE_HYG_FROM_OFFER_FAILURE, payload: err });
  }
};

export const declineCounterOffer = (jobId, counterOfferId) => async (dispatch, getState) => {
  try {
    const headers = await getAuthHeaders();

    dispatch({ type: JOB_ACTION.DECLINE_COUINTER_OFFER_REQUEST });
    const response = await tempMee.delete(`admin/jobs/${jobId}/offers/${counterOfferId}`, {
      headers,
    });

    const responseOrder = await requestPaymentService(jobId);
    dispatch({
      type: JOB_ACTION.DECLINE_COUINTER_OFFER_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });
  } catch (error) {
    dispatch({ type: JOB_ACTION.DECLINE_COUINTER_OFFER_FAILURE, payload: error });
  }
};

export const getTaxReport = () => async (dispatch, getState) => {
  try {
    dispatch({ type: REPORT_ACTIONS.FETCH_USER_TAX_REPORT_REQUEST });

    const headers = await getAuthHeaders();
    const response = await tempMee.get('admin/users/report-tax', { headers });
    dispatch({
      type: REPORT_ACTIONS.FETCH_USER_TAX_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: REPORT_ACTIONS.FETCH_USER_TAX_REPORT_FAILURE, payload: error });
  }
};

export const getJobsReport = () => async (dispatch, getState) => {
  try {
    dispatch({ type: REPORT_ACTIONS.FETCH_JOBS_REPORT_REQUEST });
    const params = getState().jobs.searchParams;
    const headers = await getAuthHeaders();
    const response = await tempMee.get('admin/jobs/report', { headers, params });

    dispatch({
      type: REPORT_ACTIONS.FETCH_JOBS_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: REPORT_ACTIONS.FETCH_JOBS_REPORT_FAILURE, payload: error });
  }
};

export const editJob = (jobId, data) => async (dispatch, getState) => {
  dispatch({ type: EDIT_JOB_REQUEST });

  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `admin/jobs/${jobId}`,
      { ...data, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
      {
        headers,
      },
    );

    const responseOrder = await requestPaymentService(jobId);
    dispatch({
      type: EDIT_JOB_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The job has been updated successfully.' },
    });
  } catch (err) {
    dispatch({ type: EDIT_JOB_FAILURE, payload: err });
  }
};

export const disablePayout = (disabledPayout) => async (dispatch, getState) => {
  dispatch({ type: DISABLE_PAYOUT_REQUEST });

  try {
    const jobId = getState().jobs.currentJob.id;
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `admin/jobs/${jobId}/payout-disabled`,
      { disabledPayout },
      { headers },
    );

    const responseOrder = await requestPaymentService(jobId);
    dispatch({
      type: DISABLE_PAYOUT_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The value for disable payout was updated successfully.' },
    });
  } catch (err) {
    dispatch({ type: DISABLE_PAYOUT_FAILURE, payload: err });
  }
};

export const enableAutoRefund = (isAutoRefundEnabled) => async (dispatch, getState) => {
  dispatch({ type: ENABLE_AUTO_REFUND_REQUEST });

  try {
    const jobId = getState().jobs.currentJob.id;
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `admin/jobs/${jobId}/auto-refund-enabled`,
      { isAutoRefundEnabled },
      { headers },
    );

    const responseOrder = await requestPaymentService(jobId);
    dispatch({
      type: ENABLE_AUTO_REFUND_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The value for auto refund was updated successfully.' },
    });
  } catch (err) {
    dispatch({ type: ENABLE_AUTO_REFUND_FAILURE, payload: err });
  }
};

export const stopAdditionalCharges = (stopCharges) => async (dispatch, getState) => {
  dispatch({ type: STOP_ADDITIONAL_CHARGES_REQUEST });

  try {
    const jobId = getState().jobs.currentJob.id;
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `/admin/jobs/${jobId}/stopCharges`,
      { stopCharges },
      { headers },
    );

    const responseOrder = await requestPaymentService(jobId);
    dispatch({
      type: STOP_ADDITIONAL_CHARGES_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The value for stop additional charges was updated successfully.' },
    });
  } catch (err) {
    dispatch({ type: STOP_ADDITIONAL_CHARGES_FAILURE, payload: err });
  }
};

export const stopInvoices = (value) => async (dispatch, getState) => {
  dispatch({ type: STOP_INVOICES_REQUEST });

  try {
    const jobId = getState().jobs.currentJob.id;
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `/admin/jobs/${jobId}/stop-invoice`,
      { stopInvoices: value },
      { headers },
    );

    const responseOrder = await requestPaymentService(jobId);
    dispatch({
      type: STOP_INVOICES_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The value for stop invoices was updated successfully.' },
    });
  } catch (err) {
    dispatch({ type: STOP_INVOICES_FAILURE, payload: err });
  }
};

export const adjustmentChange = (jobId, adjId, status) => async (dispatch, getState) => {
  dispatch({ type: ADJUSTMENT_CHANGE_REQUEST });
  try {
    const headers = await getAuthHeaders();

    const response = await tempMee.patch(
      `admin/jobs/${jobId}/adjust/${adjId}`,
      { status },
      {
        headers,
      },
    );
    dispatch({ type: ADJUSTMENT_CHANGE_SUCCESS, payload: { adjId, status } });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The job has been updated successfully.' },
    });
  } catch (err) {
    dispatch({ type: ADJUSTMENT_CHANGE_FAILURE, payload: err });
  }
};

export const fetchJob = (jobId) => async (dispatch) => {
  dispatch({ type: FETCH_JOB_REQUEST });

  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get(`admin/jobs/${jobId}`, {
      headers,
    });

    const responseOrder = await requestPaymentService(jobId);

    dispatch({
      type: FETCH_JOB_SUCCESS,
      payload: {
        ...response.data,
        transactionsPaymentService: responseOrder?.data || [],
      },
    });
  } catch (err) {
    dispatch({ type: FETCH_JOB_FAILURE, payload: err });
  }
};

export const fetchJobViews = (jobId) => async (dispatch) => {
  dispatch({ type: FETCH_JOB_VIEWS_REQUEST });

  try {
    const headers = await getAuthHeaders();

    const viewsResponse = await tempMee.get(`offer-discovery/admin/offers/views`, {
      headers,
      params: {
        job_id: jobId,
      },
    });

    dispatch({
      type: FETCH_JOB_VIEWS_SUCCESS,
      payload: {
        views: viewsResponse.data.offer_views,
      },
    });
  } catch (err) {
    dispatch({ type: FETCH_JOB_VIEWS_FAILURE, payload: err });
  }
};

export const fetchJobViewsFromJobViewService = (jobId) => async (dispatch) => {
  dispatch({ type: FETCH_JOB_VIEWS_FROM_JOB_VIEW_SERVICE_REQUEST });

  try {
    const headers = await getAuthHeaders();

    const response = await tempMee.get(`job-views/views/${jobId}`, {
      headers,
    });

    dispatch({
      type: FETCH_JOB_VIEWS_FROM_JOB_VIEW_SERVICE_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: FETCH_JOB_VIEWS_FROM_JOB_VIEW_SERVICE_FAILURE, payload: err });
  }
};

export const cancelJob = (jobId, userId, isPendingJob) => async (dispatch, getState) => {
  dispatch({ type: CANCEL_JOB_REQUEST });

  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.delete(`admin/jobs/${jobId}`, {
      headers,
      data: { userId, isPendingJob },
    });

    const responseOrder = await requestPaymentService(jobId);
    dispatch({
      type: CANCEL_JOB_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The job was canceled successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: CANCEL_JOB_FAILURE, payload: err });
  }
};

export const confirmJob = (jobId, offerId) => async (dispatch, getState) => {
  dispatch({ type: JOB_ACTION.CONFIRM_JOB_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `admin/jobs/${jobId}/offers/${offerId}/confirm`,
      {},
      { headers },
    );

    const responseOrder = await requestPaymentService(jobId);
    dispatch({
      type: JOB_ACTION.CONFIRM_JOB_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });
  } catch (err) {
    dispatch({ type: JOB_ACTION.CONFIRM_JOB_FAILURE, payload: err });
  }
};

export const acceptJob = (jobId, data) => async (dispatch, getState) => {
  dispatch({ type: ACCEPT_JOB_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(`admin/jobs/${jobId}/accept`, data, {
      headers,
    });

    const responseOrder = await requestPaymentService(jobId);
    dispatch({
      type: ACCEPT_JOB_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });
  } catch (err) {
    dispatch({ type: ACCEPT_JOB_FAILURE, payload: err });
  }
};

export const payoutJob = (jobId) => async (dispatch, getState) => {
  dispatch({ type: PAYOUT_JOB_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(
      `/admin/jobs/${jobId}/payout`,
      {},
      {
        headers,
      },
    );

    const responseOrder = await requestPaymentService(jobId);
    dispatch({
      type: PAYOUT_JOB_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'Hygienist Paid Successfully' },
    });
  } catch (err) {
    dispatch({ type: PAYOUT_JOB_FAILURE, payload: err });
  }
};

export const refundJob =
  (jobId, refundObject, transactionId, description) => async (dispatch, getState) => {
    dispatch({ type: REFUND_JOB_REQUEST });
    try {
      const headers = await getAuthHeaders();
      const response = await tempMee.post(
        `/admin/jobs/${jobId}/refund`,
        { refundObject, transactionId, description },
        {
          headers,
        },
      );

      const responseOrder = await requestPaymentService(jobId);
      dispatch({
        type: REFUND_JOB_SUCCESS,
        payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
      });

      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: 'Dentist Refund Successfully' },
      });
    } catch (err) {
      dispatch({ type: REFUND_JOB_FAILURE, payload: err });
    }
  };

export const createJob = (data) => async (dispatch, getState) => {
  dispatch({ type: CREATE_JOB_REQUEST });

  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post('admin/jobs', data, {
      headers,
    });

    const responseOrder = await requestPaymentService(response.data.id);
    dispatch({
      type: CREATE_JOB_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The job was created successfully.',
      },
    });
    history.push(`/job/${response.data.id}`);
  } catch (err) {
    dispatch({ type: CREATE_JOB_FAILURE, payload: err });
  }
};

export const getAllProfessions = () => async (dispatch, getState) => {
  dispatch({ type: GET_ALL_PROFESSIONS_REQUEST });

  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get('admin/professions', {
      headers,
    });

    dispatch({ type: GET_ALL_PROFESSIONS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_ALL_PROFESSIONS_FAILURE, payload: err });
  }
};

export const getAllSpecializations = () => async (dispatch, getState) => {
  dispatch({ type: GET_ALL_SPECIALIZATIONS_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get('specializations', {
      headers,
    });
    dispatch({ type: GET_ALL_SPECIALIZATIONS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_ALL_SPECIALIZATIONS_FAILURE, payload: err });
  }
};

export const getAllSoftwares = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ALL_SOFTWARES_REQUEST });

    const headers = await getAuthHeaders();
    const response = await tempMee.get('softwares', {
      headers,
    });

    dispatch({ type: GET_ALL_SOFTWARES_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_ALL_SOFTWARES_FAILURE, payload: err });
  }
};

export const addShiftNote = (shiftId, content) => async (dispatch) => {
  dispatch({ type: ADD_SHIFT_NOTE_REQUEST });
  try {
    const headers = await getAuthHeaders();

    const response = await tempMee.post(`admin/jobs/${shiftId}/notes`, { content }, { headers });
    dispatch({
      type: ADD_SHIFT_NOTE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: ADD_SHIFT_NOTE_FAILURE, payload: error });
  }
};

export const fetchJobNotes = (shiftId) => async (dispatch) => {
  dispatch({ type: FETCH_SHIFT_NOTES_REQUEST });
  try {
    const headers = await getAuthHeaders();

    const response = await tempMee.get(`admin/jobs/${shiftId}/notes`, { headers });
    dispatch({
      type: FETCH_SHIFT_NOTES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: FETCH_SHIFT_NOTES_FAILURE, payload: error });
  }
};

export const submitAdjustment = (jobId, content) => async (dispatch) => {
  dispatch({ type: JOB_ACTION.SUBMIT_ADJUSTMENT_REQUEST });
  try {
    const headers = await getAuthHeaders();

    const response = await tempMee.post(`admin/jobs/${jobId}/adjust`, content, { headers });
    dispatch({
      type: JOB_ACTION.SUBMIT_ADJUSTMENT_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The adjustment has been submitted successfully.' },
    });
    dispatch(fetchJob(jobId));
  } catch (error) {
    dispatch({ type: JOB_ACTION.SUBMIT_ADJUSTMENT_FAILURE, payload: error });
  }
};

export const removeJobUnavailableUsers =
  (jobId, userIds = []) =>
    async (dispatch) => {
      dispatch({ type: JOB_ACTION.REMOVE_JOB_UNAVAILABLE_USERS_REQUEST });
      try {
        const headers = await getAuthHeaders();

        await tempMee.delete(`admin/jobs/${jobId}/offer-unavailable`, {
          data: userIds,
          headers,
        });
        dispatch({
          type: JOB_ACTION.REMOVE_JOB_UNAVAILABLE_USERS_SUCCESS,
          payload: userIds,
        });
        dispatch({
          type: SHOW_MESSAGE,
          payload: { message: 'Users has been removed from unavailable list successfully.' },
        });
      } catch (error) {
        dispatch({ type: JOB_ACTION.REMOVE_JOB_UNAVAILABLE_USERS_FAILURE, payload: error });
      }
    };

export const bulkCancelJobs =
  (jobIds = []) =>
    async (dispatch) => {
      dispatch({ type: JOB_ACTION.BULK_CANCEL_JOBS_REQUEST });
      try {
        const headers = await getAuthHeaders();

        const response = await tempMee.delete(`admin/jobs/`, {
          data: { jobIds },
          headers,
        });
        dispatch({ type: JOB_ACTION.BULK_CANCEL_JOBS_SUCCESS, payload: response.data?.jobs });
        dispatch({
          type: SHOW_MESSAGE,
          payload: { message: response.data?.message },
        });
      } catch (error) {
        dispatch({ type: JOB_ACTION.BULK_CANCEL_JOBS_FAILURE, payload: error });
      }
    };
