import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import {
  fetchUserWorkerClassification,
  importToTrinet,
  saveUserWorkerClassification,
  sendOfficeReleaseForm,
} from '../../actions/userActions';
import { createLoadingSelector } from '../../apis/selectors';
import { officeTypes } from '../../constants';
import doubleCheck from '../../images/double-check.svg';
import WarningAlertIcon from '../../images/warning_alert_outline.svg';
import { capitalizeEveryFirstLetter } from '../../utils';
import CustomPortal from './CustomPortal';
import LineInfo from './LineInfo/LineInfo';

const loadingSelector = createLoadingSelector([
  'FETCH_USER_WORKER_CLASSIFICATION',
  'SEND_OFFICE_RELEASE_FORM',
  'SAVE_USER_WORKER_CLASSIFICATION',
]);

export default function WorkerClassification() {
  const dispatch = useDispatch();
  const workerClassificationInfo = useSelector(
    (state) => state.users.currentUser?.workerClassificationInfo,
  );

  const user = useSelector((state) => state.users.currentUser);
  const workerClassification = useSelector((state) => state.users.currentUser.workerClassification);
  const officeReleaseForm = useSelector(
    (state) => state.users.currentUser?.office_info?.officeReleaseForm,
  );
  const [selectedWCType, setSelectedWCType] = useState(null);
  const isLoading = useSelector((state) => loadingSelector(state));

  useEffect(() => {
    dispatch(fetchUserWorkerClassification());
  }, [dispatch]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
            marginLeft: 20,
            marginTop: 10,
          }}
        />
      </div>
    );
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          width: '70%',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: 40,
        }}
      >
        <div
          style={{
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span style={{ ...styles.title, marginBottom: 20 }}>Worker Type</span>

          <LineInfo
            title="Office Type"
            info={
              workerClassificationInfo?.officeType
                ? officeTypes.find(({ value }) => value === workerClassificationInfo?.officeType)
                  ?.title
                : '-'
            }
          />
          <LineInfo
            title="Worker Type"
            info={
              workerClassificationInfo?.workerClassification
                ? capitalizeEveryFirstLetter(workerClassificationInfo?.workerClassification)
                : '-'
            }
          />
          <LineInfo
            title="Worker Type Request Status"
            info={
              workerClassificationInfo?.workerClassificationRequestStatus
                ? capitalizeEveryFirstLetter(
                  workerClassificationInfo?.workerClassificationRequestStatus,
                )
                : '-'
            }
          />
          <div
            style={{
              width: '100%',
              borderBottom: '1px solid #efefef',
              padding: 5,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p className="col-6" style={{ fontWeight: '600' }}>
              Office Release Form
            </p>
            <div>
              {officeReleaseForm?.status === 'not_sent' && (
                <button
                  type="button"
                  className="btn btn-success"
                  style={{
                    alignSelf: 'center',
                  }}
                  onClick={() => {
                    dispatch(sendOfficeReleaseForm());
                  }}
                >
                  Send Release Form
                </button>
              )}
              {officeReleaseForm?.status === 'sent' && (
                <button
                  type="button"
                  className="btn btn-success"
                  disabled
                  style={{
                    backgroundColor: '#C0E3B6',
                    border: 'none',
                    display: 'flex',
                    gap: 4,
                  }}
                  onClick={() => {}}
                >
                  <img
                    src={doubleCheck}
                    style={{ width: 18, objectFit: 'contain' }}
                    alt="warning_alert"
                  />
                  Sent
                </button>
              )}
              {officeReleaseForm?.status === 'received' && (
                <p className="col-6" style={{ textAlign: 'right' }}>
                  Received
                </p>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            marginTop: 32,
            gap: 20,
          }}
        >
          <button
            className="btn btn-info mt-2"
            style={{ width: 100, fontSize: 16, backgroundColor: '#B4E5E7', border: 'none' }}
            onClick={() => {
              setSelectedWCType('contractor');
            }}
            type="button"
            disabled
            {...(officeReleaseForm?.status === 'received' &&
              !workerClassification?.classificationType && {
              disabled: false,
              style: {
                backgroundColor: '#4FC0CF',
              },
            })}
          >
            Contractor
          </button>
          <button
            className="btn btn-info mt-2"
            style={{ width: 100, fontSize: 16, backgroundColor: '#B4E5E7', border: 'none' }}
            onClick={() => {
              setSelectedWCType('employee');
            }}
            type="button"
            disabled
            {...(officeReleaseForm?.status === 'received' &&
              !workerClassification?.classificationType && {
              disabled: false,
              style: {
                backgroundColor: '#4FC0CF',
              },
            })}
          >
            Employee
          </button>
        </div>

        <button
          className="btn btn-info"
          style={{
            marginTop: 15,
          }}
          onClick={() => dispatch(importToTrinet(user?.id))}
          type="button"
        >
          Import to TriNet
        </button>

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 80,
          }}
        >
          <span style={{ ...styles.title, marginBottom: 24 }}>Request & Approval History</span>
        </div>

        <table style={{ width: '100%', marginBottom: 80 }}>
          <tr style={{ backgroundColor: '#ADD8E6' }}>
            <th style={{ padding: 15, fontSize: 16, textAlign: 'center' }}>Date</th>
            <th style={{ padding: 15, fontSize: 16, textAlign: 'center' }}>Action</th>
            <th style={{ padding: 15, fontSize: 16, textAlign: 'center' }}>Worker Type</th>
            <th style={{ padding: 15, fontSize: 16, textAlign: 'center' }}>Author</th>
          </tr>

          {workerClassificationInfo?.workerClassificationRequest.map((request) => (
            <tr style={{ border: '1px solid #efefef', textAlign: 'center' }}>
              <td style={{ padding: 15, fontSize: 15 }}>
                {request.createdAt ? moment(request.createdAt).format('MM/DD/YYYY') : '-'}
              </td>
              <td style={{ padding: 15, fontSize: 15 }}>
                {request.status ? capitalizeEveryFirstLetter(request.status) : '-'}
              </td>
              <td style={{ padding: 15, fontSize: 15 }}>
                {request.requestWorkerClassification
                  ? capitalizeEveryFirstLetter(request.requestWorkerClassification)
                  : '-'}
              </td>
              <td style={{ padding: 15, fontSize: 15 }}>{request.author?.fullName}</td>
            </tr>
          ))}
        </table>

        {!workerClassificationInfo?.workerClassificationRequest.length && (
          <div
            style={{
              border: '1px solid #efefef',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              padding: '20px 0px',
            }}
          >
            <san>No history found</san>
          </div>
        )}
      </div>

      {selectedWCType && (
        <CustomPortal
          Icon={WarningAlertIcon}
          title="Please Confirm"
          text={
            <div style={{ marginBottom: 80 }}>
              Please confirm that your selection is {capitalizeEveryFirstLetter(selectedWCType)}{' '}
              professionals at {user?.office_name}.
            </div>
          }
          textStyle={{ textAlign: 'center' }}
          submitText="Confirm"
          cancelText="Go Back"
          submitPressed={async () => {
            dispatch(saveUserWorkerClassification(selectedWCType));
            setSelectedWCType(null);
          }}
          cancelPressed={() => {
            setSelectedWCType(null);
          }}
        />
      )}
    </div>
  );
}

const styles = {
  title: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 400,
  },
};
