import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSwitch from 'react-switch';
import {
  fetchNotificationSettings,
  updateUserNotificationSettings,
} from '../../actions/userActions';
import { useNotificationTypeMap } from '../../constants';
import warningAlert from '../../images/warning_alert.svg';

export default function NotificationSettings() {
  const dispatch = useDispatch();
  const notificationTypeMap = useNotificationTypeMap();

  const shouldShowWarning = (type, settings) =>
    notificationTypeMap[type]?.isAlertAvailable &&
    (notificationTypeMap[type]?.isPush ? !settings?.push : true) &&
    (notificationTypeMap[type]?.isEmail ? !settings?.email : true) &&
    (notificationTypeMap[type]?.isText ? !settings?.text : true);

  const [currentSettings, setCurrentSettings] = useState([]);

  const notificationSettings = useSelector(
    (state) => state.users.currentUser?.notificationSettings,
  );

  useEffect(() => {
    dispatch(fetchNotificationSettings());
  }, []);

  useEffect(() => {
    if (notificationSettings) {
      setCurrentSettings(reorderNotifications(notificationSettings, notificationTypeMap));
    }
  }, [notificationSettings]);

  const renderSwitch = (code, checked, eventType) => {
    const titleMap = {
      push: 'Push',
      email: 'Email',
      text: 'SMS/Text',
    };

    return (
      <div style={{ display: 'flex', alignItems: 'center', columnGap: 20 }}>
        <ReactSwitch
          checked={checked}
          onChange={() => {
            const newSettings = currentSettings.map((notification) => {
              if (notification.event === eventType) {
                return {
                  ...notification,
                  [code]: !checked,
                };
              }

              return notification;
            });

            setCurrentSettings(newSettings);
          }}
          onColor="#34C9EB"
          onHandleColor="#fff"
          handleDiameter={20}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={25}
          width={50}
          className="react-switch"
          id="material-switch"
        />

        <h5 className="global_font bold" style={{ color: '#303F46', margin: 0 }}>
          {titleMap[code]}
        </h5>
      </div>
    );
  };

  const renderNotificationSection = (type, settings) => {
    const showWarning = shouldShowWarning(type, settings);

    if (!notificationTypeMap[type]) {
      return <></>;
    }

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          borderBottom: '1px solid #EDEDED',
          padding: '20px 0px',
        }}
      >
        <div style={{ width: '50%', marginLeft: 20 }}>
          <div style={{ display: 'flex' }}>
            <h5
              className="global_font bold"
              style={{
                fontSize: 16,
                color: '#303F46',
                marginBottom: 10,
                marginRight: 10,
              }}
            >
              {notificationTypeMap?.[type]?.title}
            </h5>

            {showWarning && (
              <img
                src={warningAlert}
                style={{ width: 18, objectFit: 'contain' }}
                alt="warning_alert"
              />
            )}
          </div>

          <p className="global_font" style={{ color: '#435963' }}>
            {notificationTypeMap?.[type]?.description}
          </p>
        </div>

        <div
          style={{
            marginLeft: 120,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            rowGap: 18,
          }}
        >
          {settings?.push !== undefined &&
            notificationTypeMap?.[type]?.isPush &&
            renderSwitch('push', settings?.push, type)}
          {settings?.email !== undefined &&
            notificationTypeMap?.[type]?.isEmail &&
            renderSwitch('email', settings?.email, type)}
          {settings?.text !== undefined &&
            notificationTypeMap?.[type]?.isText &&
            renderSwitch('text', settings?.text, type)}
        </div>
      </div>
    );
  };

  return (
    <div
      className="card mt-3 ml-4 mr-4 mb-3"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        padding: '0px 90px',
      }}
    >
      <h1
        className="global_font f-dark"
        style={{
          fontSize: 30,
          marginTop: 45,
          marginLeft: 30,
          textAlign: 'center',
        }}
      >
        Notification Settings
      </h1>

      <div style={{ width: '100%', borderBottom: '1px solid #EDEDED' }}>
        <h5 className="global_font bold" style={{ fontSize: 16, color: '#3EB0CA', marginLeft: 20 }}>
          Notification Type
        </h5>
      </div>

      {currentSettings?.map(({ event, ...settings }) => (
        <>{renderNotificationSection(event, settings)}</>
      ))}

      <button
        type="button"
        className="btn btn-info mr-1 ml-1"
        style={{ width: 100, margin: '80px 0px 45px 0px' }}
        onClick={() => dispatch(updateUserNotificationSettings(currentSettings))}
      >
        Save
      </button>
    </div>
  );
}

const reorderNotifications = (notifications, notificationTypeMap) => {
  const orderMap = {
    OFFICE_EVENT_SINGLE_SHIFT_JOB_POSTED: 1,
    OFFICE_EVENT_MULTI_DAY_JOB_POSTED: 2,
    OFFICE_EVENT_OFFER_RECEIVED: 3,
    OFFICE_EVENT_COUNTER_OFFER_RECEIVED: 4,
    OFFICE_EVENT_COUNTER_OFFER_EXPIRATION_WARNING_1_HOUR: 5,
    OFFICE_EVENT_COUNTER_OFFER_EXPIRATION_WARNING_15_MINUTES: 6,
    OFFICE_EVENT_OFFER_EXPIRATION: 7,
    OFFICE_EVENT_MESSAGE_RECEIVED: 8,
    OFFICE_EVENT_JOB_CANCELLED_BY_PROFESSIONAL: 9,
    OFFICE_EVENT_CANCELLED_JOB_REFILLED: 10,
  };

  notifications.sort((a, b) => {
    const indexA = orderMap[a.event];
    const indexB = orderMap[b.event];

    if (indexA === undefined && indexB === undefined) {
      return 0;
    }
    if (indexA === undefined) {
      return 1;
    }
    if (indexB === undefined) {
      return -1;
    }
    return indexA - indexB;
  });

  return notifications.filter((notification) => !notificationTypeMap[notification.event]?.hidden);
};
