import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import history from '../../../history';
import { useHighlyRatedList } from './hooks/useHighlyRatedList';

export default function HighlyRatedList() {
  const { data, isLoadingFirstPage, isLoading, handleScrollList } = useHighlyRatedList();
  const allProfessions = useSelector((state) => state.jobs.allProfessions);

  if (isLoadingFirstPage) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
            marginLeft: 20,
            marginTop: 10,
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        padding: '0px 90px',
        marginTop: '50px',
      }}
    >
      <div style={{ width: '100%', marginBottom: 20 }}>
        <h5 className="bold global_font">
          This page contains a list of professionals that the office&apos;s last rating was 4.2 or
          higher
        </h5>
      </div>
      <div style={{ width: '100%', maxHeight: 480, overflowY: 'auto' }} onScroll={handleScrollList}>
        <table style={{ width: '100%' }}>
          <tr style={{ backgroundColor: '#ADD8E6' }}>
            <th style={{ padding: 15, fontSize: 16, textAlign: 'center' }}>Action</th>
            <th style={{ padding: 15, fontSize: 16, textAlign: 'center' }}>Name</th>
            <th style={{ padding: 15, fontSize: 16, textAlign: 'center' }}>Profession</th>
          </tr>

          {data?.map(({ job, professional }) => (
            <tr style={{ border: '1px solid #efefef', textAlign: 'center' }}>
              <td style={{ padding: 15, fontSize: 15 }}>
                <a
                  style={{ cursor: 'pointer', color: '#3eb0ca', textDecorationLine: 'underline' }}
                  onClick={(event) => {
                    event.preventDefault();
                    history.push(`/user/hygienist/${professional?.id}`);
                  }}
                  href={`/user/hygienist/${professional?.id}`}
                >
                  View Account
                </a>
              </td>
              <td style={{ padding: 15, fontSize: 15 }}>{professional?.fullName}</td>
              <td style={{ padding: 15, fontSize: 15 }}>
                {allProfessions?.find((profession) => profession?.code === job?.profession)?.name}
              </td>
            </tr>
          ))}
        </table>

        {isLoading && (
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 20 }}
          >
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 30,
                width: 30,
                marginLeft: 20,
                marginTop: 10,
              }}
            />
          </div>
        )}
      </div>

      {!data?.length && (
        <div
          style={{
            border: '1px solid #efefef',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: '20px 0px',
          }}
        >
          <h3 className="bold global_font">
            This office does not have any highly-rated professionals
          </h3>
        </div>
      )}
    </div>
  );
}
