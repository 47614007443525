import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { getUserReceipts } from '../../../../actions/userActions';
import history from '../../../../history';

const LIMIT_VALUE = 25;

const normalText = {
  fontFamily: 'Nunito',
  fontSize: 16,
  color: '#435963',
  textTransform: 'capitalize',
  width: '20%',
  margin: 10,
  textAlign: 'center',
};

const titleText = {
  fontFamily: 'Nunito',
  fontWeight: 'bold',
  fontSize: '18px',
  color: '#3EB0CA',
  textTransform: 'capitalize',
  width: '20%',
  textAlign: 'center',
};

export default function ReceiptList() {
  const dispatch = useDispatch();

  const { receipts, receiptsPagination, isFinalPage, isActiveRequest } = useSelector(
    (state) => state.users.officeReceipts,
  );

  useEffect(() => {
    dispatch(getUserReceipts({ page: 1, limit: LIMIT_VALUE }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const profileContentSection = document.getElementById('profile-content');

    if (profileContentSection != null) {
      profileContentSection.addEventListener('scroll', handleReceiptScrolling);
    }

    return () => {
      if (profileContentSection != null) {
        profileContentSection.removeEventListener('scroll', handleReceiptScrolling);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptsPagination, receipts]);

  const handleReceiptScrolling = useCallback(
    (event) => {
      // tolerance is now set to height of navbar when it's on bigger size state
      // need to dynamically get from `.navbar` component later
      const tolerance = 102;
      const sectionHeight = event.target.getBoundingClientRect().height;
      const elementMaxScrollExtent = event.target.scrollHeight - tolerance;
      const scrollProgress = event.target.scrollTop + sectionHeight;

      if (scrollProgress >= elementMaxScrollExtent && !isActiveRequest && !isFinalPage) {
        dispatch(getUserReceipts({ page: receiptsPagination?.page + 1, limit: LIMIT_VALUE }));
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [receiptsPagination, receipts],
  );

  if (receipts.length === 0) {
    return (
      <div className="component_container">
        <div className="floating_container">
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              margin: 55,
            }}
          >
            <p
              style={{
                fontSize: 16,
                color: '#546e7a',
                textAlign: 'center',
                marginTop: 20,
              }}
            >
              There are no receipts.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: 40,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 20,
            overflowY: 'auto',
          }}
        >
          <h1 className="global_font f-dark" style={{ marginLeft: 30, textAlign: 'center' }}>
            Receipts
          </h1>
          <div style={{ overflowY: 'scroll' }}>
            <div
              style={{
                flex: 1,
                margin: 10,
                display: 'flex',
              }}
            >
              <span style={titleText}>Shift</span>
              <span style={titleText}>Office</span>

              <span style={titleText}>Amount</span>
              <span style={titleText}>Shift Date</span>
              <span style={titleText}> payment method</span>

              <span style={titleText}>Charge date</span>
              <span style={titleText}>Status</span>
            </div>
            <div style={{ height: 1, backgroundColor: '#F6F6F6' }} />
            {receipts.map((receipt, index) => (
              <div
                role="presentation"
                key={receipt.id}
                style={{
                  flex: 1,
                  margin: 10,
                  display: 'flex',
                  backgroundColor: index % 2 === 0 ? '#F6F6F6' : '#FAFAFA',
                  cursor: 'pointer',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '30px',
                }}
                onClick={() =>
                  history.push(`/job/${receipt?.jobId}`, {
                    job_id: receipt?.jobId,
                    jobId: receipt?.jobId,
                  })
                }
              >
                <span
                  style={{
                    fontFamily: 'Nunito',
                    fontSize: 16,
                    color: '#435963',
                    textTransform: 'capitalize',
                    width: '20%',
                    margin: 10,
                    textAlign: 'center',
                    textDecoration: 'underline',
                  }}
                >
                  View Shift
                </span>

                <span style={normalText}>{receipt.offer_owner.office_name}</span>
                <span
                  style={{
                    fontFamily: 'Nunito',
                    fontSize: 16,
                    color: '#435963',
                    textTransform: 'capitalize',
                    width: '20%',
                    margin: 10,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  {receipt.type === 'credit'
                    ? `- $${receipt.amount.toFixed(2)}`
                    : `$${receipt.amount.toFixed(2)}`}
                </span>
                <span style={normalText}>
                  {moment(receipt.jobDate).format('ddd, MMM DD, YYYY')}
                </span>

                <span style={normalText}>
                  {receipt.payment_method?.brand} ****
                  {receipt.payment_method?.last4}
                </span>
                <span style={normalText}>
                  {`${moment(receipt.date).format('MM-DD-YYYY HH:mm')}`}
                </span>
                <span
                  style={{
                    fontFamily: 'Nunito',
                    fontSize: 16,
                    color: receipt.status === 'failed' ? '#F4511E' : '#15ab7b',
                    textTransform: 'capitalize',
                    width: '20%',
                    margin: 10,
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  {receipt.type === 'credit' ? `Refund ${receipt.status}` : receipt.status}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
